import React, { useCallback } from 'react'
import { CrazyCardProps } from './CrazyCard.types'
import {
  ActionLabel,
  BaseCard,
  Container,
  CrazyButton
} from './CrazyCard.styles'
import { feedbackcard } from '@yes.technology/react-toolkit'
import {
  postWorkflow,
  PostWorkflowResponse
} from 'workflow/shared/api/postWorkflow'
import { useTranslation } from 'react-i18n-lite'

function CrazyCard({ des, workflowmodeluuid }: CrazyCardProps) {
  const { t } = useTranslation()

  const onButtonClick = useCallback(() => {
    feedbackcard(t('one-click.start.title'), {
      type: 'warning',
      message: t('one-click.start.message')
    })

    postWorkflow({ workflowmodel_uuid: workflowmodeluuid }, false)
      .then((response: PostWorkflowResponse) => {
        if (response.success) {
          feedbackcard(t('one-click.success.title'), {
            type: 'success',
            message: t('one-click.success.message')
          })
        } else {
          feedbackcard(t('one-click.error.title'), {
            type: 'error',
            message: t('one-click.error.message')
          })
        }
      })
      .catch(() => {
        feedbackcard(t('one-click.error.title'), {
          type: 'error',
          message: t('one-click.error.message')
        })
      })
  }, [t, workflowmodeluuid])

  return (
    <BaseCard fullHeight>
      <Container>
        <ActionLabel variant='content-emphasis-06'>{des}</ActionLabel>

        <CrazyButton type='button' aria-label={des} onClick={onButtonClick} />
      </Container>
    </BaseCard>
  )
}

export default CrazyCard
