import { Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const OuterContainer = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`

export const InnerText = styled(Text)`
  text-align: center;
`
