import { useMemo, useRef, useState } from 'react'
import { Icon } from '@yes.technology/react-toolkit'

import {
  ExpandedButton,
  StyledChartWrapper,
  StyledHierarchyCard
} from '../Cockpit.styles'
import GoogleChart from './GoogleChart'
import { ChartProps, ChartTemplate } from '../Cockpit.types'
import { useTranslation } from 'react-i18n-lite'
import BrazilMap from './BrazilMap'
import BarChart from './BarChart'
import CardChart from './CardChart'
import SaFChart, { SaFChartProps } from './SaFChart'
import useExpandedChartStyles from '../hooks/useExpandedChartStyles/useExpandedChartStyles'

export type DataChartValue = string | number | null

export type DataChart = [string, DataChartValue, string?, string?, string?][]

const customCharts = ['BarChart', 'GeoChart', 'CardChart', 'SaFChart']

const ChartWrapper = ({
  uuid,
  des,
  dataset,
  template,
  column = 'col-md-12'
}: ChartProps) => {
  const { t } = useTranslation()

  const [expandedChart, setExpandedChart] = useState(false)

  const chartData: DataChart | SaFChartProps['data'] = useMemo(() => {
    try {
      return typeof dataset === 'string' ? JSON.parse(dataset) : []
    } catch (error) {
      console.error('Error parsing JSON:', error)
      return []
    }
  }, [dataset])

  const chartTemplate: ChartTemplate = useMemo(() => {
    try {
      return typeof template === 'string' ? JSON.parse(template) : {}
    } catch (error) {
      console.error('Error parsing JSON:', error)
      return {}
    }
  }, [template])

  const chartRef = useRef<HTMLDivElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)

  const expandedChartStyles = useExpandedChartStyles({
    chartRef,
    chartWrapperRef,
    expandedChart
  })

  return (
    <StyledChartWrapper
      $expanded={expandedChart}
      className={`mb-3 ${column}`}
      ref={chartWrapperRef}
    >
      <StyledHierarchyCard style={{ position: 'relative', height: '100%' }}>
        <ExpandedButton
          title={`${expandedChart ? t('cockpit.chart.collapse-button-label') : t('cockpit.chart.expand-button-label')}`}
          onClick={() => setExpandedChart(!expandedChart)}
        >
          <Icon
            iconName={`${expandedChart ? 'Contract' : 'Expand'}`}
            icSize='semiLarge'
          />
        </ExpandedButton>

        <div ref={chartRef} style={expandedChartStyles}>
          {chartTemplate.chart_model === 'BarChart' && (
            <BarChart
              chartModel={chartTemplate.chart_model}
              data={chartData as DataChart}
              options={chartTemplate.options}
              title={des}
            />
          )}

          {chartTemplate.chart_model === 'CardChart' && (
            <CardChart
              chartModel={chartTemplate.chart_model}
              data={chartData as DataChart}
              title={des}
            />
          )}

          {chartTemplate.chart_model === 'GeoChart' && (
            <BrazilMap
              chartModel={chartTemplate.chart_model}
              data={chartData as DataChart}
              options={chartTemplate.options}
              title={des}
              format={chartTemplate?.format}
            />
          )}

          {chartTemplate.chart_model === 'SaFChart' && (
            <SaFChart data={chartData as SaFChartProps['data']} title={des} />
          )}

          {!customCharts.includes(chartTemplate.chart_model) && (
            <GoogleChart
              chartUuid={uuid}
              chartModel={chartTemplate.chart_model}
              data={chartData as DataChart}
              options={chartTemplate.options}
              title={des}
            />
          )}
        </div>
      </StyledHierarchyCard>
    </StyledChartWrapper>
  )
}

export default ChartWrapper
