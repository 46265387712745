import { FilterQuery, Objectclass } from '@yes.technology/react-toolkit'
import { isEqual, uniq } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFilterFields } from 'shared/hooks'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { FieldSettings } from 'shared/types'
import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { useSiteState } from 'siteState/shared'
import { FilterForm } from './components'
import { FilterAreaLayout } from './types'
import { useSitemodel } from 'sitemodel/shared/hooks'

export type FilterFieldsDisplayOptions = {
  [elementUuid: string]: {
    hideFilteraction?: boolean
    hideMainCollapsibleContainer?: boolean
    hideFieldsCollapsibleContainer?: boolean
  }
}

type FilterFieldsProps = {
  props: {
    elementUuid: string
    uuid_objectclass: string
    uuid_classification?: string
    defaultExecutedFilteraction?: string
    navigationslider_background_color?: string
    navigationslider_color?: string
    filter_area_layout?: FilterAreaLayout
    field_settings?: FieldSettings
    styleMap?: StyleMap
    filter_query_target_uuid?: string
  }
}

const FilterFields = ({ props }: FilterFieldsProps) => {
  const sitemodel = useSitemodel()

  const {
    elementUuid,
    uuid_objectclass: objectclassUuid,
    uuid_classification: classificationUuid,
    defaultExecutedFilteraction,
    navigationslider_background_color: navigationsliderBackgroundColor,
    navigationslider_color: navigationsliderColor,
    filter_area_layout: filterAreaLayout,
    field_settings: fieldSettings,
    filter_query_target_uuid: filterQueryTargetUuid,
    styleMap
  } = props

  const styleProp = useStyleMaps(styleMap)
  const {
    filteractions: { hide_filteraction: hideFilteraction = false } = {},
    hide_main_collapsible_container: hideMainCollapsibleContainer = false,
    hide_fields_collapsible_container: hideFieldsCollapsibleContainer = false
  } = filterAreaLayout || {}

  const [, setSiteQuery] = useSiteState<Partial<FilterQuery>>(
    'filter-query',
    filterQueryTargetUuid
  )
  const [, setSiteObjectIndex] = useSiteState<number | undefined>(
    'object-index'
  )
  const [siteFields, setSiteFields] =
    useSiteState<FilterFieldsResponse>('filter-fields')
  const [, setObjectclassUUID] = useSiteState<string>('objectclassuuid')
  const [, setFilterObjectclass] = useSiteState<Objectclass | undefined>(
    'filter-objectclass'
  )
  const [, setDisplayOptions] = useSiteState<FilterFieldsDisplayOptions>(
    'filter-display-options'
  )
  const [, setRefetchFilterQuery] = useSiteState(
    'refetch-filterquery',
    filterQueryTargetUuid
  )

  const [, setBoundFilterQueryElements] = useSiteState<string[]>(
    'bound-filter-query-elements'
  )

  const [searchParams] = useSearchParams()

  const fields = useFilterFields({
    objectclassUuid,
    classificationUuid,
    filterAreaLayout,
    fieldSettings
  })

  const initialGroupedFieldValues = useMemo(
    () => ({ 1: fields.initialFieldValues }),
    [fields.initialFieldValues]
  )

  useEffect(() => {
    setFilterObjectclass(fields.objectclass)
  }, [fields, setFilterObjectclass])

  useEffect(() => {
    setDisplayOptions((prevState) => ({
      ...prevState,
      [elementUuid]: {
        hideFilteraction,
        hideMainCollapsibleContainer,
        hideFieldsCollapsibleContainer
      }
    }))
  }, [
    elementUuid,
    hideFieldsCollapsibleContainer,
    hideFilteraction,
    hideMainCollapsibleContainer,
    setDisplayOptions
  ])

  useEffect(() => {
    if (filterQueryTargetUuid) {
      setBoundFilterQueryElements((prevState) =>
        uniq([...(prevState ?? []), filterQueryTargetUuid])
      )
    }
  }, [filterQueryTargetUuid, setBoundFilterQueryElements])

  const onClickFilter = useCallback(
    (query: Partial<FilterQuery>) => {
      if (isEqual(fields, siteFields)) {
        setRefetchFilterQuery(true)
      }
      setSiteObjectIndex(undefined)
      setSiteQuery(query)
      setSiteFields(fields)
      setObjectclassUUID(objectclassUuid)
    },
    [
      fields,
      siteFields,
      setSiteObjectIndex,
      setSiteQuery,
      setSiteFields,
      setObjectclassUUID,
      objectclassUuid,
      setRefetchFilterQuery
    ]
  )

  const key = useMemo(() => JSON.stringify(fields), [fields])

  const filteractionFromQueryParams: string =
    searchParams.get('uuid_filteraction') || ''

  const defaultExecutedFilteractionValue =
    defaultExecutedFilteraction || filteractionFromQueryParams

  if (!fields.classifiedFields[0].length) return null

  const sitemodelTitle = sitemodel?.title
  const objectclassName = fields?.objectclass?.des

  return (
    <FilterForm
      key={key}
      filterAreaLayout={filterAreaLayout}
      initialGroupedFieldValues={initialGroupedFieldValues}
      sections={fields.classifiedFields}
      onSubmit={onClickFilter}
      title={sitemodelTitle || objectclassName}
      uuidCurrentSitemodelObjectclass={props.uuid_objectclass}
      defaultExecutedFilteraction={defaultExecutedFilteractionValue}
      navigationSliderBackgroundColor={navigationsliderBackgroundColor}
      navigationSliderColor={navigationsliderColor}
      style={styleProp}
      {...{ elementUuid, fieldSettings }}
    />
  )
}

export default FilterFields
