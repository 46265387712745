import { useMemo } from 'react'
import { DeviceType } from 'site/shared/types'
import useWindowDimensions from './useWindowDimensions'

const useDeviceType = (): DeviceType => {
  const { windowWidth } = useWindowDimensions()

  const deviceType = useMemo(() => {
    if (windowWidth < 768) {
      return 'mobile'
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      return 'tablet'
    } else {
      return 'desktop'
    }
  }, [windowWidth])

  return deviceType
}

export default useDeviceType
