import { AreaLine, useIsMobile } from '@yes.technology/react-toolkit'

import { TopAreaLayoutType, TopAreaPositionType } from 'site/shared/types'

import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

import CollapsibleMenu from './CollapsibleMenu/CollapsibleMenu'
import DesktopLanguageContainer from './LanguageMenu/DesktopLanguageContainer'
import Position from './Position'
import { TopLinePropsType } from './TopLine.types'

import { StyledContainer } from './styles'

export type TopLineProps = {
  props: TopLinePropsType
}

export const renderArea = (
  position?: TopAreaPositionType[],
  isCenter?: boolean
) => (
  <div className={`d-flex gap-3 ${isCenter ? 'w-full' : ''}`}>
    {position?.map((component, index) => (
      <Position key={index} {...component} />
    ))}
  </div>
)

export const parseLayout = (
  layout?: TopAreaLayoutType | string
): TopAreaLayoutType | undefined => {
  let parsedData

  if (typeof layout === 'string') {
    try {
      parsedData = JSON.parse(layout) as TopAreaLayoutType
    } catch (error) {
      parsedData = {}
    }
  } else {
    parsedData = layout
  }

  return parsedData
}

const TopLine = ({ props }: TopLineProps) => {
  const layout = parseLayout(props?.layout)

  const styleProp = useStyleMaps(props?.styleMap)

  const isMobile = useIsMobile()

  const placement = props.placement || 'default'

  const availableLanguages = props.available_languages

  if (!layout) return null

  if (isMobile) {
    return (
      <CollapsibleMenu
        layout={layout}
        availableLanguages={availableLanguages}
      />
    )
  }

  if (placement !== 'default') {
    return (
      <AreaLine
        className='navbar-expand-lg'
        leftAreaElement={renderArea(layout.position_1)}
        centerAreaElement={renderArea(layout.position_2, true)}
        rightAreaElement={
          availableLanguages ? (
            <DesktopLanguageContainer availableLanguages={availableLanguages}>
              {renderArea(layout.position_3)}
            </DesktopLanguageContainer>
          ) : (
            renderArea(layout.position_3)
          )
        }
        alignment='left'
        colorScheme='white'
        heightClass='medium'
        placement={placement}
        style={styleProp}
      />
    )
  }

  return (
    <StyledContainer style={styleProp}>
      <AreaLine
        className='navbar-expand-lg'
        leftAreaElement={renderArea(layout.position_1)}
        centerAreaElement={renderArea(layout.position_2, true)}
        rightAreaElement={renderArea(layout.position_3)}
        alignment='left'
        colorScheme='white'
        heightClass='medium'
        placement={placement}
      />
    </StyledContainer>
  )
}

export default TopLine
