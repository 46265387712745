import ptBR from '../../../../../locales/images/pt-BR.png'
import enUS from '../../../../../locales/images/en-US.png'
import deDE from '../../../../../locales/images/de-DE.png'
import es419 from '../../../../../locales/images/es-419.png'

const languageImages = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'de-DE': deDE,
  'es-419': es419
}

export type Lang = keyof typeof languageImages

export const getLanguageImageSrc = (lang: Lang) => languageImages[lang]
