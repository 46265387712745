import { Field, SituationObject } from '@yes.technology/react-toolkit'
import { FilteractionSelectionProps } from './FilteractionSelection'
import { FieldValues, GroupedFieldValues } from '../../types/fields'
import getQueryDataFromFilteraction from './getQueryDataFromFilteraction'

type GetOnSelectFilteractionProps = {
  sections: Field[][]
  situations: Pick<
    SituationObject,
    'code' | 'des' | 'uuid' | 'uuid_Objectclass'
  >[]
  initialFieldValues: FieldValues
  handleChange?: (value: GroupedFieldValues) => void
  handleClear?: () => void
  handleComposition: (composition: string) => void
}

const getOnSelectFilteraction =
  ({
    sections,
    situations,
    initialFieldValues,
    handleChange = () => '',
    handleClear = () => '',
    handleComposition
  }: GetOnSelectFilteractionProps): FilteractionSelectionProps['onSelectFilteraction'] =>
  (filteraction) => {
    handleClear()

    if (!filteraction) {
      return
    }

    const { queryData, composition } = getQueryDataFromFilteraction({
      initialFieldValues,
      sections: sections,
      situations,
      filteraction
    })

    handleChange(queryData)
    handleComposition(composition)
  }

export default getOnSelectFilteraction
