import { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'

import { useTranslation } from 'react-i18n-lite'

const useHandleLanguage = () => {
  const { setLanguage } = useTranslation()

  const [searchParams] = useSearchParams()

  const lang = searchParams.get('lang')

  useEffect(() => {
    if (lang) setLanguage(lang)
  }, [lang, setLanguage])
}

export default useHandleLanguage
