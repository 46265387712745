import { merge } from 'lodash'

import { locales as toolkitLocales } from '@yes.technology/react-toolkit'

//  Default locale
import localePtBr from './pt-BR.json'

// Type workaround to ensure that all locale files will have the same set of keys
type Locale = typeof localePtBr
type ToolkitLocale = (typeof toolkitLocales)['pt-BR']
type LocalesObject = { [key: string]: Locale & ToolkitLocale }

// Additional locales
import localeEnUs from './en-US.json'
import localeDeDe from './de-DE.json'
import localeEs419 from './es-419.json'

const locales: LocalesObject = {
  'pt-BR': merge(toolkitLocales['pt-BR'], localePtBr),
  'en-US': merge(toolkitLocales['en-US'], localeEnUs),
  'de-DE': merge(toolkitLocales['de-DE'], localeDeDe),
  'es-419': merge(toolkitLocales['es-419'], localeEs419)
}

export default locales
