import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 608px;
    border: unset;
  }
`

export const SliderButton = styled.button<{
  $color?: string
}>`
  background-color: transparent;
  border: none;
  border-radius: 100%;
  padding: 0;
  outline: 0;

  > figure {
    outline: 0;

    width: 40px;
    height: 40px;
  }

  &:focus {
    outline: none;
    > figure {
      > svg > path:first-child {
        fill: #abbed1;
      }
    }
  }

  > figure {
    pointer-events: none;
    &:focus {
      outline: none;

      > svg > path:first-child {
        fill: #abbed1;
      }
    }
  }
`

export const CenterText = styled.div<{ $color?: string }>`
  text-align: center;
  color: ${(props) => props.$color || '#303032'};
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`
