import { useLocation } from 'react-router'
import { useEffect, useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  SitemodelManifestStateGrouped,
  ComponentPropsType
} from 'sitemodel/shared/types/sitemodel.type'
import { AuthenticationType } from 'authentication/shared/types'
import useSitemodelState from '../useSitemodelState/useSitemodelState'
import { getApplicationSystemInfo } from 'shared/utils'

interface Credentials {
  user_uuid: string
  organization_uuid: string
  applicationsystem_uuid: string
}

interface DecodedToken {
  credentials: Credentials
}

export interface ApplicationSystem {
  owner: string
}

const useSitemodelLocalParams = (stateMap: SitemodelManifestStateGrouped) => {
  const { mergeSitemodelState } = useSitemodelState()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  const token = useSelector(
    ({ authentication }: AuthenticationType) => authentication?.token
  )

  const jwtDecoded = useMemo(() => {
    if (!token) return null
    return jwtDecode<DecodedToken>(token)
  }, [token])

  const credentials = useMemo<Partial<Credentials>>(() => {
    return jwtDecoded?.credentials || {}
  }, [jwtDecoded?.credentials])

  const environment = useMemo(() => {
    const currentUrl = `${window.location.hostname}${pathname}`

    const rawEnvironment =
      window.environment.filter(
        (environmentObject) => Object.keys(environmentObject)[0] === currentUrl
      )[0]?.[currentUrl] || {}

    const parsedEnvironment: Record<string, string> = {
      application_system: rawEnvironment.application_system,
      organization: rawEnvironment.credentials?.organization,
      environment: rawEnvironment.credentials?.environment,
      application_system_model:
        rawEnvironment.credentials?.application_system_model
    }

    return parsedEnvironment
  }, [pathname])

  const applicationSystem = useMemo<Partial<ApplicationSystem>>(() => {
    const { ownerUuid } = getApplicationSystemInfo()
    return { owner: ownerUuid }
  }, [])

  useEffect(() => {
    const newSitemodelState: ComponentPropsType = {}

    Object.keys(stateMap.querystring).forEach((key) => {
      const queryStringKey = stateMap.querystring[key].querystring
      newSitemodelState[key] = searchParams.get(queryStringKey) || undefined
    })

    Object.keys(stateMap.environment).forEach((key) => {
      const environmentKey = stateMap.environment[key].environment
      newSitemodelState[key] = environment[environmentKey]
    })

    Object.keys(stateMap.credential).forEach((key) => {
      const credentialsKey = stateMap.credential[key].credential
      newSitemodelState[key] = credentials[credentialsKey as keyof Credentials]
    })

    Object.keys(stateMap.applicationsystem).forEach((key) => {
      const applicationsystemKey =
        stateMap.applicationsystem[key].applicationsystem
      newSitemodelState[key] =
        applicationSystem[applicationsystemKey as keyof ApplicationSystem]
    })

    mergeSitemodelState(newSitemodelState)
  }, [
    stateMap,
    searchParams,
    environment,
    credentials,
    applicationSystem,
    mergeSitemodelState
  ])
}

export default useSitemodelLocalParams
