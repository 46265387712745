import { DeviceType } from 'site/shared/types'
import { CardCarouselPageSize } from '../CardCarousel.types'

export const PAGE_SIZE: CardCarouselPageSize = {
  mobile: 1,
  tablet: 2,
  desktop: 4
}

function mapPageAccordingToDeviceType(
  previousDevicePage: number,
  previousDeviceType: DeviceType,
  currentDeviceType: DeviceType
): number {
  const previousPageSize = PAGE_SIZE[previousDeviceType]
  const currentPageSize = PAGE_SIZE[currentDeviceType]

  const totalItemsDisplayed = previousPageSize * previousDevicePage
  const newPageNumber = Math.floor(totalItemsDisplayed / currentPageSize)

  return newPageNumber
}

export default mapPageAccordingToDeviceType
