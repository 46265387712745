import { Style } from '@react-pdf/types'

const header: Style = {
  flexDirection: 'row',
  alignItems: 'center',
  padding: 8,
  height: 40
}

const logo: Style = {
  width: 'auto',
  height: 24
}

const title: Style = {
  color: '#303032',
  fontSize: 12,
  fontFamily: 'Barlow',
  fontWeight: 600,
  width: '100%',
  textAlign: 'center'
}

export default function getStyles() {
  return {
    header,
    title,
    logo
  }
}
