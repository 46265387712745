import { SortOption } from '@yes.technology/react-toolkit'
import {
  Array,
  Dictionary,
  Literal,
  Optional,
  Record,
  String,
  Union,
  Unknown
} from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type CardModel =
  | 'GenericObjectCard'
  | 'TaskObjectCard'
  | 'ContactObjectCard'
  | 'TransportOrderCard'

const CardModelGuard = Union(
  Literal('GenericObjectCard'),
  Literal('TaskObjectCard'),
  Literal('ContactObjectCard'),
  Literal('TransportOrderCard')
)

const ColumnTypeGuard = Record({
  grouped_by_value: String,
  label: String
})

const LayoutTypeGuard = Record({
  order_by: Optional(Array(Dictionary(String, String))),
  sections: Optional(Array(Record({ columns: Array(ColumnTypeGuard) })))
})

export type ColumnProps = {
  grouped_by_value: string
  label: string
}

type SectionProps = {
  columns: ColumnProps[]
}

export type LayoutProps = {
  order_by?: SortOption[]
  sections?: SectionProps[]
}

export type KanbanCardsProps = {
  props: {
    uuid_objectclass: string
    uuid_filteraction?: string
    uuid_statusaction_classification?: string
    card_model: CardModel
    group_by: string
    layout?: LayoutProps
    styleMap?: StyleMap
  }
}

export const KanbanTypeGuard = Record({
  uuid_objectclass: String,
  uuid_filteraction: Optional(String),
  uuid_statusaction_classification: Optional(String),
  card_model: CardModelGuard,
  group_by: String,
  layout: Optional(LayoutTypeGuard),
  styleMap: Optional(Unknown)
})
