import { Divider } from '@yes.technology/react-toolkit'
import {
  Literal,
  Optional,
  Record as RunTypeRecord,
  Union,
  Unknown
} from 'runtypes'
export type DividerProps = {
  props: {
    styleMap?: Record<string, unknown>
    displayBlock?: boolean
  } & React.ComponentProps<typeof Divider>
}
export const DividerTypeGuard = RunTypeRecord({
  heightClass: Optional(
    Union(
      Literal('extraSmall'),
      Literal('small'),
      Literal('semiSmall'),
      Literal('regular')
    )
  ),
  spacingClass: Optional(
    Union(
      Literal('extraSmall'),
      Literal('small'),
      Literal('semiSmall'),
      Literal('regular'),
      Literal('semiLarge'),
      Literal('large'),
      Literal('extraLarge'),
      Literal('semiGiant')
    )
  ),
  color: Optional(
    Union(Literal('gray'), Literal('mediumGray'), Literal('lightGray'))
  ),
  orientation: Optional(Union(Literal('horizontal'), Literal('vertical'))),
  styleMap: Optional(Unknown)
})
