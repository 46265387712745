import apiAsync from 'infrastructure/shared/api/api-async'
import Workflow from '../types/workflow.type'

export type PostWorkflowPayload = {
  workflowmodel_uuid: string
}

export type PostWorkflowResponse = {
  data: {
    workflow: Workflow
  }
  success: boolean
  message: string
}

export const postWorkflow = (
  payload: PostWorkflowPayload,
  showLoading = true
): Promise<PostWorkflowResponse> => {
  return apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: 'workflows',
    method: 'POST',
    showLoading: showLoading,
    obj: payload
  })
}
