import { FilterQuery, YesObject } from '@yes.technology/react-toolkit'
import { useState } from 'react'
import { useSiteState } from 'siteState/shared'
import useTableContext from '../useTableContext'
import { utils, writeFile } from 'xlsx'
import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'

const MAX_XLS_CELL_LENGTH = 32767

const formatValue = (value: YesObject[string]) => {
  const stringValue =
    value instanceof Array ? value.map((v) => v.des).join(', ') : value

  return stringValue?.length > MAX_XLS_CELL_LENGTH
    ? `${stringValue.slice(0, MAX_XLS_CELL_LENGTH - 3)}...`
    : stringValue
}

const formatObjects = (
  objects: YesObject[],
  columns: string[],
  labelsByColumn: Record<string, string>
) => {
  return objects.map((object) => {
    return columns.reduce((acc, column) => {
      return {
        ...acc,
        [labelsByColumn[column]]: formatValue(object[column])
      }
    }, {})
  })
}

export default function useExportXLS() {
  const [filterQuery] = useSiteState<FilterQuery>('filter-query')
  const [objectclassUUID] = useSiteState<string>('objectclassuuid')
  const [isLoading, setIsLoading] = useState(false)

  const { allColumns, sortOptions, fields = {} } = useTableContext()

  const labelsByColumn = Object.entries(fields).reduce(
    (acc, [columnName, field]) => {
      return { ...acc, [columnName]: field.display_text.des }
    },
    {}
  )

  const exportXLSReport = async () => {
    if (!allColumns) return

    setIsLoading(true)

    try {
      const filterBody = {
        query: filterQuery,
        objectclass: objectclassUUID,
        orderby: sortOptions,
        pagination: {
          limit: 500,
          offset: 0
        }
      }

      const { objects } = await fetchYesObjects({
        filterBody,
        showLoading: false
      })

      const formattedObjects = formatObjects(
        objects || [],
        allColumns,
        labelsByColumn
      )

      const ws = utils.json_to_sheet(formattedObjects)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Data')
      writeFile(wb, 'report.xlsx')
    } catch (error: unknown) {
      console.error(error)
    }

    setIsLoading(false)
  }

  return {
    exportXLSReport,
    isLoading
  }
}
