import { useCallback, useState } from 'react'

import {
  AdvancedMarker,
  CollisionBehavior,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps'

import { YesObject } from '@yes.technology/react-toolkit'

import { DeliveryPointInfoWindow } from './DeliveryPointInfoWindow'
import { validateAndParseCoordinates } from './utils/address'

import { MarkerProps } from './MapContainer.types'

export const MapMarker = ({ object }: MarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef()
  const [infoWindowShown, setInfoWindowShown] = useState(false)

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  )

  const handleClose = useCallback(() => setInfoWindowShown(false), [])

  const coordinates = validateAndParseCoordinates(
    object?.address?.[0] as YesObject
  )

  if (!coordinates) return null

  return (
    <>
      <AdvancedMarker
        collisionBehavior={CollisionBehavior.REQUIRED}
        ref={markerRef}
        onClick={handleMarkerClick}
        position={{
          lat: coordinates.lat,
          lng: coordinates.lng
        }}
        title={object.des}
      >
        <Pin
          background={'#006BFF'}
          borderColor={'#006BFF'}
          glyphColor={'#fff'}
          scale={1.3}
        />
      </AdvancedMarker>
      {infoWindowShown && (
        <InfoWindow
          anchor={marker}
          minWidth={350}
          maxWidth={430}
          onClose={handleClose}
        >
          <DeliveryPointInfoWindow {...{ object }} />
        </InfoWindow>
      )}
    </>
  )
}
