import { Text, View } from '@react-pdf/renderer'
import styles from './styles'
import PDFTableCell from './RelationTableCell'
import { Objectclass, PDFCard, YesObject } from '@yes.technology/react-toolkit'
import {
  DEFAULT_TABLE_LAYOUT,
  TableLayout
} from 'site/Renderer/components/DataTables/hooks/useTableLayouts'
import PDFAreaLine from 'report/components/PDFAreaLine'
import PDFDataTable from 'report/components/PDFDataTable/PDFDataTable'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { useFetchYesObjects } from 'yesObject/shared'

type RelationDataTableProps = {
  columns: string[]
  fields: Objectclass['fields']
  objects: YesObject[]
  relationName: string
  tableLayout?: TableLayout
  concatFields?: ConcatFields
}

function RelationDataTable({
  columns,
  fields,
  objects,
  relationName,
  tableLayout = DEFAULT_TABLE_LAYOUT[0],
  concatFields
}: RelationDataTableProps) {
  const displayedColumns = tableLayout?.columnsLayouts
    .map((layout) =>
      columns.find((column) => layout.field_names.includes(column))
    )
    .filter(Boolean) as string[]

  return (
    <View>
      <PDFDataTable
        objects={objects}
        fields={fields}
        columns={displayedColumns}
        columnsLayouts={tableLayout?.columnsLayouts}
        itemsPerPage={10}
        title={relationName}
        concatFields={concatFields}
      />
    </View>
  )
}

export default RelationDataTable
