import { Text } from '@yes.technology/react-toolkit'
import { EventCardProps, ImageFormat } from './EventCard.type'
import {
  Card,
  Container,
  Content,
  ImageWrapper,
  Subtitle,
  TextContainer
} from './EventCard.styles'
import ImageDocument from 'component/ImageDocument/ImageDocument'

const EventCard = ({
  des,
  startDate,
  endDate,
  children,
  documents,
  documentFormat = 'square'
}: EventCardProps) => {
  const finalDocumentFormat: ImageFormat = documentFormat
  const isHorizontalAligned = finalDocumentFormat !== 'portrait'

  const renderImage = () =>
    documents && (
      <ImageWrapper $format={finalDocumentFormat}>
        <ImageDocument documents={documents} />
      </ImageWrapper>
    )

  return (
    <Card>
      <Container>
        {!isHorizontalAligned && renderImage()}
        <Content>
          <Text variant='label-required-04' as='h3'>
            {des}
          </Text>
          <Subtitle variant='bordertext-emphasis-04'>
            {startDate}
            {endDate && (
              <>
                <br /> {endDate}
              </>
            )}
          </Subtitle>
          {isHorizontalAligned && renderImage()}
          <TextContainer>{children}</TextContainer>
        </Content>
      </Container>
    </Card>
  )
}

export default EventCard
