import { Accordion } from '@yes.technology/react-toolkit'
import { Fragment } from 'react/jsx-runtime'
import { Component } from 'site/shared/types'
import {
  StyledAccordionCard,
  StyledCardWrapper
} from './GenericObjectCardContentWrapper.styles'
import useGenericObjectCardWrapper from './hooks/useGenericObjectCardWrapper'

export type AccordionProps = React.ComponentProps<typeof Accordion>

export type GenericObjectCardContentWrapperProps = {
  children: React.ReactNode
  wrapperType: 'Accordion' | 'Fragment' | 'Management'
  wrapperProps?: AccordionProps
  components?: Component[]
}

const AccordionCard = (props: AccordionProps) => {
  return (
    <StyledAccordionCard>
      <Accordion {...props} />
    </StyledAccordionCard>
  )
}

const WrapperComponent: Record<
  GenericObjectCardContentWrapperProps['wrapperType'],
  React.ElementType
> = {
  Accordion: AccordionCard,
  Fragment: Fragment,
  Management: StyledCardWrapper
}

const GenericObjectCardContentWrapper = ({
  children,
  wrapperProps,
  wrapperType,
  components
}: GenericObjectCardContentWrapperProps) => {
  const Component = WrapperComponent[wrapperType]

  const { props } = useGenericObjectCardWrapper({
    children,
    wrapperProps,
    wrapperType,
    components
  })

  return <Component {...props}>{children}</Component>
}

export default GenericObjectCardContentWrapper
