import { HeightClass } from 'shared/types/toolkit'
import styled from 'styled-components'
import { tabsComponentSizes } from './Tabs.enum'

export const ItemContainer = styled.div<{ heightClass: HeightClass }>`
  display: inline-flex;
  gap: ${({ heightClass }) => tabsComponentSizes[heightClass].spaceBetween}px;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  align-items: center;
  overflow: hidden;
`

export const ItemButton = styled.button<{ focusable: boolean }>`
  all: unset;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    text-decoration: underline 2px;
    text-decoration-color: #abbed1;
  }

  &[aria-selected='true']:disabled {
    text-decoration: underline dashed 1px;
  }

  ${({ focusable }) =>
    !focusable &&
    `
    &[aria-selected="true"] {
      text-decoration: underline 4px;
    }
  `}
  > span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const VerticalSeparator = styled.div<{ heightClass: HeightClass }>`
  height: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].separatorHeight}px;

  border: 0;
  border-left: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].separatorThickness}px;
  border-color: #000;
  border-style: solid;
`
