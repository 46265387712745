import { Component } from 'site/shared/types'

export enum ActionButtonEnum {
  default = 'default',
  workflowmodel = 'workflowmodel',
  helptext = 'helptext',
  link = 'link',
  oneclick = 'oneclick'
}

export type ActionButtonType =
  | ActionButtonEnum.default
  | ActionButtonEnum.workflowmodel
  | ActionButtonEnum.helptext
  | ActionButtonEnum.link
  | ActionButtonEnum.oneclick

export type ActionButtonProps = {
  type: ActionButtonType
  action: string
  label: string
}

export type SitemodelContent = {
  card_preview_components?: Component[]
  action_buttons?: ActionButtonProps[]
}
