import {
  Button,
  LinkButton,
  GenericObjectCard as ToolkitGenObjCard,
  YesObject
} from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'

import CrazyCard from 'component/CrazyCard'
import ImageDocument from 'component/ImageDocument/ImageDocument'
import React from 'react'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

import {
  ActionButtonEnum,
  ActionButtonProps,
  ActionButtonType,
  SitemodelContent
} from './GenericObjectCard.types'
import GenericObjectCardContentWrapper, {
  GenericObjectCardContentWrapperProps
} from './GenericObjectCardContentWrapper'

export type GenericObjectCardProps = Pick<YesObject, 'des' | 'complement'> &
  Partial<Pick<YesObject, 'sitemodel_content' | 'Document'>> & {
    CustomAction?: React.ReactNode
    buttonProps?: React.ComponentProps<typeof Button>
    customWrapperProps?: GenericObjectCardContentWrapperProps['wrapperProps']
    wrapperType?: GenericObjectCardContentWrapperProps['wrapperType']
    enableContentFading?: boolean
    complementLines?: number
  } & Partial<
    Pick<
      React.ComponentProps<typeof ToolkitGenObjCard>,
      'imageFormat' | 'imageHeight' | 'frameImage'
    >
  >

const GenericObjectCard = ({
  des,
  complement,
  sitemodel_content: sitemodelContent,
  Document: image,
  CustomAction = null,
  buttonProps,
  imageFormat,
  imageHeight,
  frameImage,
  customWrapperProps,
  wrapperType = 'Fragment',
  enableContentFading = false,
  complementLines = 0
}: GenericObjectCardProps) => {
  const JSONContent =
    typeof sitemodelContent === 'string' ? getJSONContent(sitemodelContent) : {}

  const actionButtons = getActionButtons(JSONContent)
  const previewComponents = getPreviewComponents(JSONContent)
  const defaultButton = getButtonByType(actionButtons, ActionButtonEnum.default)
  const workflowmodelButton = getButtonByType(
    actionButtons,
    ActionButtonEnum.workflowmodel
  )
  const helptexttButton = getButtonByType(
    actionButtons,
    ActionButtonEnum.helptext
  )
  const linkButton = getButtonByType(actionButtons, ActionButtonEnum.link)
  const crazyButton = getButtonByType(actionButtons, ActionButtonEnum.oneclick)
  const href = linkButton?.action || '#'

  const imageForwardProp = image && <ImageDocument documents={image} />
  const buttonForwardProp = defaultButton ? (
    <Link
      to={`/${defaultButton.action}`}
      style={{ width: '100%', textDecoration: 'none' }}
    >
      <Button
        style={{ fontSize: '12px' }}
        heightClass='semiSmall'
        variant='secondary'
      >
        {defaultButton.label}
      </Button>
    </Link>
  ) : undefined

  const cardWrapperType =
    previewComponents && previewComponents.length > 0
      ? 'Accordion'
      : wrapperType

  if (crazyButton)
    return (
      <GenericObjectCardContentWrapper
        wrapperType={cardWrapperType}
        wrapperProps={customWrapperProps}
        components={previewComponents}
      >
        <CrazyCard
          des={crazyButton.label}
          workflowmodeluuid={crazyButton.action}
        />
      </GenericObjectCardContentWrapper>
    )

  return (
    <GenericObjectCardContentWrapper
      wrapperType={cardWrapperType}
      wrapperProps={customWrapperProps}
      components={previewComponents}
    >
      <ToolkitGenObjCard
        des={des}
        complement={<SafeHtmlContent html={complement} />}
        image={imageForwardProp}
        imageFormat={imageFormat}
        imageHeight={imageHeight}
        frameImage={frameImage}
        defaultButton={buttonForwardProp}
        fullHeight
        applyFadingOverTitle={enableContentFading}
        complementLines={complementLines}
      >
        {workflowmodelButton && (
          <Link
            to={`/${window.workflowmodelExecutorUuid}?workflowmodelUuid=${workflowmodelButton.action}`}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <Button
              {...buttonProps}
              style={{ fontSize: '10px', ...buttonProps?.style }}
              heightClass={buttonProps?.heightClass ?? 'small'}
              variant={buttonProps?.variant ?? 'primary'}
              enableFading={enableContentFading}
            >
              {workflowmodelButton.label}
            </Button>
          </Link>
        )}
        {helptexttButton && (
          <Link
            to={`/${helptexttButton.action}`}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <Button
              {...buttonProps}
              style={{ fontSize: '10px', ...buttonProps?.style }}
              heightClass={buttonProps?.heightClass ?? 'small'}
              variant={buttonProps?.variant ?? 'secondary'}
              enableFading={enableContentFading}
            >
              {helptexttButton.label}
            </Button>
          </Link>
        )}
        {linkButton && (
          <LinkButton
            heightClass={buttonProps?.heightClass ?? 'small'}
            variant={buttonProps?.variant ?? 'secondary'}
            href={href}
            target='_blank'
            rel='noopener noreferrer'
            {...(buttonProps as Partial<
              React.ComponentProps<typeof LinkButton>
            >)}
            enableFading={enableContentFading}
          >
            {linkButton?.label}
          </LinkButton>
        )}

        {CustomAction}
      </ToolkitGenObjCard>
    </GenericObjectCardContentWrapper>
  )
}

const getJSONContent = (jsonString: string) => {
  const json = JSON.parse(jsonString)
  return json
}

const getActionButtons = (content: SitemodelContent) => {
  return content.action_buttons
}

const getPreviewComponents = (content: SitemodelContent) => {
  return content.card_preview_components
}

const getButtonByType = (
  actionButtons: ActionButtonProps[] | undefined,
  type: ActionButtonType
): ActionButtonProps | null => {
  if (!actionButtons) return null
  const [first] = actionButtons?.filter((button) => button.type === type)
  return first || null
}

export default GenericObjectCard
