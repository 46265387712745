import { useTranslation } from 'react-i18n-lite'
import useTableContext from '../useTableContext'
import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import patchObject from 'yesObject/shared/api/patchObject'
import postObject from 'yesObject/shared/api/postObject'

export const SITUATION_CODE_ACTIVE = '40'

type UseTableActionSaveProps = {
  initialStatusUuid: string
}

export default function useTableActionSave({
  initialStatusUuid
}: UseTableActionSaveProps) {
  const { language } = useTranslation()

  const {
    idObjectClass = '',
    setMode,
    changedFieldValues,
    setChangedFieldValues,
    objects,
    fields,
    refetchObjects,
    mode
  } = useTableContext()

  const save = async () => {
    const saveRequestPromises = Object.entries(changedFieldValues).map(
      ([objectUuidOrIndex, changes]) => {
        const normalizedChanges = Object.fromEntries(
          Object.entries(changes).flatMap(([columnName, value]) => {
            const { type = 'STRING' } = fields?.[columnName] || {}

            if (mode === 'insert' && value.length === 0) {
              return []
            }

            if (type === 'DATETIME') {
              return [
                [
                  columnName,
                  datepickerToISOFormat(
                    typeof value === 'string' ? value : '',
                    language as Parameters<typeof datepickerToISOFormat>[1]
                  ) || ''
                ]
              ]
            }

            return [[columnName, value]]
          })
        )

        if (mode === 'insert') {
          return postObject({
            uuid_Objectclass: idObjectClass,
            uuid_status: initialStatusUuid,
            situation: SITUATION_CODE_ACTIVE,
            data: normalizedChanges,
            showLoading: true
          })
        }

        return patchObject({
          uuid: objectUuidOrIndex,
          data: {
            ...normalizedChanges,
            version: objects.find(({ uuid }) => uuid === objectUuidOrIndex)
              ?.version
          },
          showLoading: true
        })
      }
    )

    await Promise.all(saveRequestPromises)
    refetchObjects()

    setChangedFieldValues({})
    setMode('view')
  }

  return save
}
