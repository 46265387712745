import { Icon } from '@yes.technology/react-toolkit'

import { SiteFooterContainerProps } from './SiteFooterContainer.types'
import {
  Address,
  Container,
  Copyright,
  GoToTopButton,
  GoToTopContainer,
  LogoContainer,
  LogoImage,
  NavigationList,
  NavigationListItem,
  SystemMessage
} from './SiteFooterContainer.styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const SiteFooterContainer = ({ props }: SiteFooterContainerProps) => {
  const {
    ownerLinks,
    systemLinks,
    socialLinks,
    logoUrl,
    address,
    systemMessage,
    copyright,
    styleMap,
    linksStyleMap,
    dividerMainStyleMap,
    dividerStyleMap,
    goToTopStyle,
    goToTopPosition = 'after-logo'
  } = props

  const styleProps = useStyleMaps(styleMap)
  const linksStyle = useStyleMaps(linksStyleMap)
  const dividerMainStyle = useStyleMaps(dividerMainStyleMap)
  const dividerStyle = useStyleMaps(dividerStyleMap)

  const goToTopButton = (
    <GoToTopContainer data-testid='go-to-top'>
      <GoToTopButton style={goToTopStyle} onClick={goToTop}>
        <Icon iconName='DropdownArrowUpNew' />
      </GoToTopButton>
    </GoToTopContainer>
  )

  return (
    <Container style={styleProps}>
      {goToTopPosition === 'top' && goToTopButton}

      {ownerLinks && (
        <NavigationList>
          {ownerLinks?.map(({ link, label, image }, index) => (
            <NavigationListItem key={`${index}-ownerLinks`}>
              <a
                href={link}
                target='_blank'
                rel='noreferrer'
                style={linksStyle}
              >
                {image ? <img src={image} alt={label} /> : label}
              </a>
            </NavigationListItem>
          ))}
        </NavigationList>
      )}

      {logoUrl && (
        <LogoContainer>
          <LogoImage src={logoUrl} alt='Logo do Sistema de Aplicação' />
        </LogoContainer>
      )}

      {goToTopPosition === 'after-logo' && goToTopButton}

      <hr style={dividerMainStyle} />

      {systemMessage && (
        <SystemMessage>
          <SafeHtmlContent html={systemMessage} />
        </SystemMessage>
      )}

      {systemLinks && (
        <NavigationList>
          {systemLinks?.map(({ link, label, image }, index) => (
            <NavigationListItem key={`${index}-systemLinks`}>
              <a
                href={link}
                target='_blank'
                rel='noreferrer'
                style={linksStyle}
              >
                {image ? <img src={image} alt={label} /> : label}
              </a>
            </NavigationListItem>
          ))}
        </NavigationList>
      )}

      {socialLinks && (
        <NavigationList $direction='row'>
          {socialLinks.map(({ link, src, alt }, index) => (
            <NavigationListItem key={index}>
              <a href={link} target='_blank' rel='noreferrer'>
                <img {...{ src, alt }} />
              </a>
            </NavigationListItem>
          ))}
        </NavigationList>
      )}

      <hr style={dividerStyle} />

      {address && (
        <Address>
          <SafeHtmlContent html={address} />
        </Address>
      )}

      {copyright && (
        <Copyright>
          <SafeHtmlContent html={copyright} />
        </Copyright>
      )}
    </Container>
  )
}

export default SiteFooterContainer
