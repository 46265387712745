import { Style } from '@react-pdf/types'

const container: Style = {
  flexDirection: 'column',
  width: '100%',
  padding: 4,
  gap: 4
}

const card: Style = {
  flexDirection: 'column',
  gap: 4,
  padding: 4
}

const title: Style = {
  fontFamily: 'Barlow',
  fontSize: 10,
  fontWeight: 600
}

const row: Style = {
  flexDirection: 'row',
  gap: 4
}

const datafield: Style = {
  width: '100%',
  gap: 4
}

const label: Style = {
  color: '#303032',
  fontFamily: 'Barlow',
  fontSize: 8,
  fontWeight: 600,

  width: '100%',

  paddingTop: 20,
  paddingBottom: 4,

  flexDirection: 'row',
  alignItems: 'flex-end'
}

const input: Style = {
  color: '#303032',
  fontFamily: 'Barlow',
  fontSize: 8,
  fontWeight: 400,

  width: '100%',

  borderTop: 1,
  borderColor: '#ABBED1',

  padding: 4
}

export default function getStyles() {
  return {
    container,
    card,
    title,
    row,
    datafield,
    label,
    input
  }
}
