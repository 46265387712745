import { LinkButton } from '@yes.technology/react-toolkit'

import ImageDocument from 'component/ImageDocument/ImageDocument'

import { ArticleProps } from './article.types'

import {
  ArticleContent,
  ArticleImage,
  ArticleImageDocument,
  ArticleTitle,
  ButtonsContainer,
  ContentContainer,
  MainContainer
} from './styles'

function Article({
  des,
  imageUrl,
  imageAlt,
  complement,
  position,
  buttons,
  textColor,
  titleColor,
  titleOnTop,
  ...divProps
}: ArticleProps) {
  const validButtons = buttons?.filter((button) => button.label && button.href)

  return (
    <MainContainer
      noImage={!imageUrl}
      titleOnTop={!!titleOnTop}
      $reverse={position === 'right'}
      noTitle={!des}
      {...divProps}
    >
      {des && <ArticleTitle $color={titleColor}>{des}</ArticleTitle>}

      {typeof imageUrl === 'string' && (
        <ArticleImage src={imageUrl} alt={imageAlt} />
      )}

      {typeof imageUrl === 'object' && (
        <ArticleImageDocument>
          <ImageDocument documents={imageUrl} />
        </ArticleImageDocument>
      )}

      <ContentContainer noTitle={!des}>
        <ArticleContent $textColor={textColor}>{complement}</ArticleContent>
        {validButtons && validButtons.length > 0 && (
          <ButtonsContainer>
            {validButtons.map((button, index) => (
              <LinkButton
                key={index}
                href={button.href}
                variant='secondary'
                style={button.style}
              >
                {button.label}
              </LinkButton>
            ))}
          </ButtonsContainer>
        )}
      </ContentContainer>
    </MainContainer>
  )
}

export default Article
