import {
  FilterQuery,
  HierarchyCard,
  Pagination,
  SkeletonDots,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ScrollToHashElement } from 'shared/utils'
import { concatenateFields } from 'shared/utils/object/concatenateFields'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { useSiteState } from 'siteState/shared'
import { useSitemodel } from 'sitemodel/shared/hooks'
import StatusCard from 'status/StatusCard/StatusCard'
import { updateStatus, useStatusActions, useStatuses } from 'status/shared'
import {
  useFetchYesObjects,
  usePopulateObjectRelations
} from 'yesObject/shared'
import patchObject from 'yesObject/shared/api/patchObject'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import SectionDivider from '../SectionDivider'
import CancelModal from './components/CancelModal'
import Fields from './components/Fields/Fields'
import NavigationBetweenObjects from './components/NavigationBetweenObjects/NavigationBetweenObjects'
import RelationClasses from './components/RelationClasses/RelationClasses'
import ShowSituation from './components/ShowSituation/ShowSituation'
import { ObjectViewProps } from './components/types/object-view'
import useEditableFields from './hooks/useEditableFields/useEditableFields'
import useObjectFields from './hooks/useObjectFields'
import useObjectViewReports from './hooks/useObjectViewReports/useObjectViewReports'
import selectObjectSource from './lib/selectObjectSource/selectObjectSource'

type LocationState = {
  objectIndex?: number
  sortOptions: SortOption[]
  objectclassUuid: string
  pagination?: Pagination
}

const ObjectView = ({ props }: ObjectViewProps) => {
  const {
    relations_view: relationConfig,
    uuid_classification: classificationUuid,
    uuid_objectclass_query: objectclassUuidQueryKey = 'uuid_objectclass',
    informational = false,
    concat_fields: concatFields,
    field_settings: fieldSettings,
    styleMap,
    object_uuid,
    is_show_reports: isShowReports
  } = props

  const sitemodel = useSitemodel()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const locationState = location?.state as LocationState

  const [filterQuery] = useSiteState<FilterQuery | undefined>('filter-query')
  const [objectIndex, setObjectIndex] = useSiteState<number | undefined>(
    'object-index'
  )
  const [lockObjectViewObject, setLockObjectViewObject] = useSiteState<boolean>(
    'lock-object-view-object'
  )

  const styleProps = useStyleMaps(styleMap)

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [pendingObjectIndex, setPendingObjectIndex] = useState<number>()

  const onCancelClose = useCallback(() => {
    setIsCancelModalOpen(false)
  }, [])

  const [shouldUpdateURL, setShouldUpdateURL] = useState(false)

  const objectUuid = searchParams.get('object-uuid') || object_uuid || ''
  const objectclassUuid = searchParams.get(objectclassUuidQueryKey) || ''

  const objectFields = useObjectFields({
    objectclassUuid,
    classificationUuid,
    fieldSettings
  })

  const { sortOptions } = locationState || {}

  const hasObjectIndex = typeof objectIndex === 'number'
  const shouldFetchFromIndex = !!filterQuery && hasObjectIndex

  const {
    yesObjects: [objectFromIndex],
    isFetching: isFetchingObjectFromIndex,
    pagination,
    setPage,
    setSortOptions,
    fetchYesObjects: fetchObjectFromIndex
  } = useFetchYesObjects({
    filterBody: {
      query: filterQuery || {},
      objectclass: objectclassUuid
    },
    initialSortOptions: sortOptions,
    initialPagination: { limit: 1, offset: objectIndex || 0 },
    enabled: shouldFetchFromIndex
  })

  useEffect(() => {
    setSortOptions(sortOptions)
  }, [sortOptions, setSortOptions])

  const {
    yesObject: objectFromUuid,
    isFetching: isFetchingObjectFromUuid,
    fetchObjects: fetchObjectFromUuid
  } = useYesObject({
    objectUUID: objectUuid,
    objectclassUUID: objectclassUuid,
    enabled: !shouldFetchFromIndex
  })

  const { yesObject, isFetchingObject, refetchObject } = selectObjectSource({
    fetchObjectFromIndex,
    fetchObjectFromUuid,
    isFetchingObjectFromIndex,
    isFetchingObjectFromUuid,
    objectFromIndex,
    objectFromUuid
  })

  const isFetching = isFetchingObject || objectFields.isLoading

  useEffect(() => {
    if (hasObjectIndex) setPage(objectIndex + 1)
  }, [hasObjectIndex, objectIndex, setPage])

  const handleNavigation = useCallback(
    (newPage: number) => {
      setObjectIndex(newPage - 1)
      setShouldUpdateURL(true)
      setLockObjectViewObject(true)
      navigate(`${location.pathname}${location.search}#object-view`, {
        replace: true,
        state: location.state
      })
    },
    [
      location.pathname,
      location.search,
      location.state,
      navigate,
      setObjectIndex
    ]
  )

  const shouldNavigate = (newPage: number) => {
    if (isEditing) {
      setIsCancelModalOpen(true)
      setPendingObjectIndex(newPage)
      return
    }
    handleNavigation(newPage)
  }

  const updateUuidInUrl = () => {
    if (!yesObject?.uuid || yesObject.uuid === objectUuid || !shouldUpdateURL)
      return

    setShouldUpdateURL(false)
    searchParams.set('object-uuid', yesObject.uuid)
    navigate(`${location.pathname}?${searchParams}#object-view`, {
      replace: true,
      state: location.state
    })
  }

  useEffect(updateUuidInUrl, [
    yesObject?.uuid,
    objectUuid,
    searchParams,
    location,
    navigate,
    shouldUpdateURL
  ])

  const statuses = useStatuses({ objectclassUuid })
  const { statusActions } = useStatusActions({ statuses })

  const { fieldValues, isEditing, onChange, onToggleEditing } =
    useEditableFields({
      fields: objectFields.fields,
      yesObject,
      onSave(values) {
        patchObject({
          uuid: objectUuid,
          data: {
            ...values,
            uuid_Objectclass: objectclassUuid,
            version: yesObject?.version
          },
          showLoading: true
        }).then(refetchObject)
      }
    })

  const [contentMinHeight, setContentMinHeight] = useState(0)
  const updateContentMinHeightFromDom = (container: HTMLDivElement | null) => {
    if (container?.offsetHeight) {
      setContentMinHeight(container.offsetHeight)
    }
  }

  const isYesObjectEmpty = !isFetching && !yesObject
  const isObjectFieldsEmpty = !isFetching && !objectFields?.fields?.length

  const sitemodelTitle = sitemodel?.title
  const objectclassName = objectFields?.objectclass?.des
  const title = sitemodelTitle || objectclassName || ''
  const completeTitle = title
    ? `${title} | ${t('area-title.object-view')}`
    : t('area-title.object-view')

  const values = isEditing ? fieldValues : yesObject

  const concatenatedFields = concatFields
    ? concatenateFields(concatFields, values as YesObject)
    : values

  const { data } = usePopulateObjectRelations(
    concatenatedFields ? [concatenatedFields] : [],
    objectFields.relationFields
  )

  const { reportButtons, singleReport, mergedReport, batchReport } =
    useObjectViewReports({
      isFetching,
      yesObject: data[0],
      filterQuery,
      informational,
      relationConfig,
      fieldSettings,
      concatFields,
      classificationUuid,
      isShowReports
    })

  const onCancelEditing = useCallback(() => {
    setIsCancelModalOpen(false)
    onToggleEditing(true)

    if (pendingObjectIndex) handleNavigation(pendingObjectIndex)
  }, [onToggleEditing, pendingObjectIndex, handleNavigation])

  if (isYesObjectEmpty || isObjectFieldsEmpty)
    return (
      <SectionDivider
        id='object-view'
        title={completeTitle}
        centerHeaderElement={
          shouldFetchFromIndex ? (
            <NavigationBetweenObjects
              currentPosition={objectIndex}
              pagination={pagination}
              onNavigating={shouldNavigate}
            />
          ) : undefined
        }
        backgroundColor='#004099'
        disabled
        style={styleProps}
      />
    )

  return (
    <SectionDivider
      id='object-view'
      title={title}
      centerHeaderElement={
        shouldFetchFromIndex ? (
          <NavigationBetweenObjects
            currentPosition={objectIndex}
            pagination={pagination}
            onNavigating={shouldNavigate}
          />
        ) : undefined
      }
      rightHeaderElement={reportButtons}
      backgroundColor='#004099'
      style={styleProps}
    >
      <HierarchyCard className='pb-4'>
        {isFetching && (
          <div style={{ minHeight: contentMinHeight }}>
            <SkeletonDots />
          </div>
        )}

        {!isFetching && (
          <div ref={updateContentMinHeightFromDom} className='p-0'>
            <ScrollToHashElement />
            {!informational && yesObject?.uuid && yesObject?.version && (
              <div className='row g-2'>
                <StatusCard
                  informational={informational}
                  statusUuid={yesObject?.uuid_status || ''}
                  updateStatus={(destinationStatusCode) =>
                    updateStatus({
                      objectVersion: yesObject.version,
                      objectUuid: yesObject.uuid,
                      objectclassUuid,
                      destinationStatusCode,
                      showLoading: true
                    }).then(refetchObject)
                  }
                  {...{
                    statusActions,
                    statuses
                  }}
                />

                <ShowSituation
                  yesObject={yesObject}
                  situationCode={yesObject?.situation}
                  objectClassUUID={objectclassUuid}
                  objectUUID={yesObject.uuid}
                  objectVersion={yesObject.version}
                  onRefresh={refetchObject}
                  onEditCancel={() => {
                    setIsCancelModalOpen(true)
                  }}
                  mainActionLabel={isEditing ? 'Salvar e Ativar' : 'Modificar'}
                  mainActionOnClick={onToggleEditing}
                  informational={informational}
                  isEditing={isEditing}
                />
              </div>
            )}

            <CancelModal
              isOpen={isCancelModalOpen}
              onCancelClose={onCancelClose}
              onSucceed={onCancelEditing}
            />

            <Fields
              fields={objectFields.fields}
              informational={informational || !isEditing}
              fieldValues={concatenatedFields}
              onChange={onChange}
              isEditing={isEditing}
            />

            <RelationClasses
              relationFields={objectFields.relationFields}
              fieldValues={yesObject}
              relationConfig={relationConfig}
              informational={informational}
            />
          </div>
        )}
      </HierarchyCard>

      {singleReport}
      {mergedReport}
      {batchReport}
    </SectionDivider>
  )
}

export default ObjectView
