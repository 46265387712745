import { Style } from '@react-pdf/types'

const bodyContainer: Style = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: -14
}

const item: Style = { width: '24%', marginRight: 8 }

const bodyCard: Style = {
  marginTop: 12,
  marginHorizontal: 0,
  overflow: undefined
}

export default function getStyles() {
  return {
    bodyContainer,
    bodyCard,
    item
  }
}
