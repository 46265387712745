import {
  Button,
  LinkButton,
  Pagination,
  SortOption,
  SyntheticButton,
  YesObject
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { To } from 'react-router-dom'
import useNavigateMergingState from 'shared/hooks/useNavigateMergingState/useNavigateMergingState'
import { useSiteState } from 'siteState/shared'
import usePersistentSelection from '../../FilterFields/hooks/usePersistentSelection'
import { Mode } from '../hooks/useTableContext'
import { CustomActionButton } from '../types'
import DocumentActions from './DocumentActions'

export type ActionsProps = {
  informational?: boolean
  idObjectClass: string
  object: YesObject
  pagination?: Pagination
  sortOptions?: SortOption[]
  objects?: YesObject[]
  target?: '_self' | '_blank'
  type?: 'view' | 'document' | null
  objectclassQueryString: string
  mode: Mode
  customActionButton?: CustomActionButton
}

const Actions = ({
  idObjectClass,
  object,
  pagination,
  sortOptions,
  objects,
  type,
  target = '_self',
  objectclassQueryString,
  mode,
  customActionButton
}: ActionsProps) => {
  const [_, setObjectIndex] = useSiteState<number | number>('object-index')
  const [__, setLockObjectViewObject] = useSiteState<boolean>(
    'lock-object-view-object'
  )

  const { t } = useTranslation()
  const navigateMergingState = useNavigateMergingState()

  const offset = pagination?.offset || 0
  const objectIndexInPage =
    objects?.findIndex((obj) => obj.uuid === object.uuid) || 0
  const objectIndex = offset + objectIndexInPage

  const { selectAndLink, shouldRenderSelectAndLink } = usePersistentSelection()

  const getVisualizeOption = (to: To) => ({
    children: 'Visualizar',
    onClick: () => {
      setObjectIndex(objectIndex)
      navigateMergingState(to, {
        state: {
          sortOptions
        }
      })
      setLockObjectViewObject(true)
    }
  })

  const selectAndLinkOption = {
    children: t('select-and-link.action'),
    onClick: () => selectAndLink([object])
  }

  const getOptions = (to: To) => [getVisualizeOption(to), selectAndLinkOption]

  if (customActionButton) {
    return (
      <LinkButton
        variant='secondary'
        href={customActionButton.link.url}
        target={customActionButton.link.target}
        style={customActionButton.style}
      >
        {customActionButton.label}
      </LinkButton>
    )
  }

  if (type === 'document') {
    return (
      <DocumentActions
        object={object}
        target={target}
        getOptions={getOptions}
      />
    )
  }

  if (type === null) {
    return <></>
  }

  const path = {
    search: `?object-uuid=${object.uuid}&${objectclassQueryString}=${idObjectClass}`,
    hash: '#object-view'
  }

  return (
    <>
      {shouldRenderSelectAndLink() ? (
        <SyntheticButton
          options={getOptions(path)}
          heightClass='small'
          dropdownLabel='more'
        />
      ) : (
        <Button
          variant='secondary'
          heightClass='small'
          disabled={mode !== 'view'}
          onClick={() => {
            setObjectIndex(objectIndex)
            navigateMergingState(path, {
              state: {
                sortOptions
              }
            })
            setLockObjectViewObject(true)
          }}
        >
          Visualizar
        </Button>
      )}
    </>
  )
}

export default Actions
