import { YesObject } from '@yes.technology/react-toolkit'
import useSitemodelState from '../useSitemodelState/useSitemodelState'
import {
  ActionButtonEnum,
  ActionButtonProps
} from 'component/GenericObjectCard/GenericObjectCard.types'
import { useCallback } from 'react'

interface SitemodelContent {
  action_buttons?: ActionButtonProps[]
}

const getRedirectUrl = (actionButton: ActionButtonProps): string => {
  switch (actionButton.type) {
    case ActionButtonEnum.default:
    case ActionButtonEnum.helptext:
      return `/${actionButton.action}`

    case ActionButtonEnum.link:
      return actionButton.action

    case ActionButtonEnum.workflowmodel:
      return `/${window.workflowmodelExecutorUuid}?workflowmodelUuid=${actionButton.action}`
    default:
      return '#'
  }
}

const handleRedirect = (button: ActionButtonProps) => {
  const redirectUrl = getRedirectUrl(button)
  window.location.href = redirectUrl
}

const useRedirect = () => {
  const { sitemodelState } = useSitemodelState()

  const stateWithOneObject = useCallback(
    (identifier: string, onFinish?: () => void) => {
      const state = sitemodelState[identifier] as YesObject[]

      if (!Array.isArray(state)) {
        // The filteraction execution is not finished yet
        return
      }

      if (state.length !== 1) {
        onFinish?.()
        return
      }

      const sitemodelContent = state?.[0]?.sitemodel_content

      const content: SitemodelContent =
        typeof sitemodelContent === 'string' ? JSON.parse(sitemodelContent) : {}

      const actionButtons = content?.action_buttons

      if (actionButtons && actionButtons.length === 1) {
        const actionButton = actionButtons[0]

        if (actionButton.type === ActionButtonEnum.oneclick) {
          onFinish?.()
          return
        }

        handleRedirect(actionButton)
        return
      }

      onFinish?.()
    },
    [sitemodelState]
  )

  return {
    stateWithOneObject
  }
}

export default useRedirect
