import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { useSitemodelContent } from 'sitemodel/shared/hooks'
import { getApplicationSystemInfo, tagManager } from 'shared/utils'
import useSitemodelFromUrl from './shared/hooks/useSitemodelFromUrl'
import { ApplicationSystem } from 'sitemodel/shared/hooks/useSitemodelLocalParams/useSitemodelLocalParams'
import NotFoundPage from 'infrastructure/NotFoundPage'
import { Renderer } from './Renderer'
import { SitemodelContext } from 'sitemodel/shared/hooks'

const Create = () => {
  const { sitemodel, isLoading } = useSitemodelFromUrl()

  const content = useSitemodelContent({
    sitemodelContent: sitemodel?.sitemodel_content
  })

  const documentTitle = content?.title || sitemodel?.des

  const location = useLocation()

  const username = useSelector(({ authentication }) => authentication?.username)

  tagManager.setDataLayer({
    event: 'pageview',
    page: {
      url: location.pathname,
      title: documentTitle
    },
    user: {
      username
    }
  })

  const applicationSystem = useMemo<Partial<ApplicationSystem>>(() => {
    const { ownerUuid } = getApplicationSystemInfo()
    return { owner: ownerUuid || '' }
  }, [])

  const cloudfrontUrl = window.cloudfrontUrl

  if (!isLoading && !Object.keys(content).length) {
    return <NotFoundPage />
  }

  if (!content?.service) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
        {applicationSystem?.owner && (
          <link
            rel='icon'
            href={`${cloudfrontUrl}/static-files/favicon/${applicationSystem.owner}.ico`}
          />
        )}
        {applicationSystem?.owner && (
          <link
            rel='manifest'
            href={`${cloudfrontUrl}/static-files/pwa/${applicationSystem.owner}/manifest.json`}
            crossOrigin='anonymous'
          />
        )}
      </Helmet>

      <SitemodelContext.Provider value={content}>
        <Renderer {...content} />
      </SitemodelContext.Provider>
    </>
  )
}

export default Create
