import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18n-lite'

import { Button, Icon, LinkButton } from '@yes.technology/react-toolkit'

import { TaskActionsContainer, TaskActionsRow } from './TaskObjectCard.styles'

import { TaskActionsProps } from './TaskObjectCard.types'

const TaskActions = ({
  statusUuid,
  taskUuid,
  statusActions,
  primaryStatusActionUuid,
  changeStatus
}: TaskActionsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isWaitingInteraction =
    window.waitingInteractionStatusUuid === statusUuid

  const validStatusActions = statusActions.filter(
    (statusAction) => statusAction.destination_status?.[0]
  )

  const sortedvalidStatusActions = [
    ...validStatusActions.filter(
      (statusAction) => statusAction.uuid === primaryStatusActionUuid
    ),
    ...validStatusActions.filter(
      (statusAction) => statusAction.uuid !== primaryStatusActionUuid
    )
  ]

  const visualizeButton = (
    <LinkButton
      key='view'
      variant='secondary'
      heightClass='small'
      style={{ width: '24px', minWidth: '24px' }}
      aria-label={t('task.action.view')}
      href={`/${window.objectDetailsSitemodelUuid}?uuid_objectclass=${window.uuidObjectclassTask}&object-uuid=${taskUuid}`}
    >
      <Icon iconName='Visualization' icSize='small' />
    </LinkButton>
  )

  return (
    <TaskActionsContainer>
      {isWaitingInteraction && (
        <TaskActionsRow>
          <Button
            variant='primary'
            heightClass='small'
            onClick={() => navigate(`/tasks/${taskUuid}`)}
          >
            {t('task.action.execute')}
          </Button>
          {!validStatusActions.length && visualizeButton}
        </TaskActionsRow>
      )}
      {sortedvalidStatusActions.map((statusAction, i) => (
        <TaskActionsRow key={statusAction.destination_status[0].code}>
          <Button
            variant={
              !isWaitingInteraction &&
              statusAction.uuid === primaryStatusActionUuid
                ? 'primary'
                : 'secondary'
            }
            heightClass='small'
            onClick={() =>
              changeStatus(statusAction.destination_status[0].code)
            }
          >
            {statusAction.des}
          </Button>
          {i === sortedvalidStatusActions.length - 1 && visualizeButton}
        </TaskActionsRow>
      ))}
    </TaskActionsContainer>
  )
}

export default TaskActions
