import {
  Button,
  Icon,
  InformationCard,
  SafeHtmlContent
} from '@yes.technology/react-toolkit'
import React from 'react'
import { InnerText, OuterContainer } from './CancelModal.styles'
import { useTranslation } from 'react-i18n-lite'

type CancelModalProps = {
  isOpen: boolean
  onCancelClose: () => void
  onSucceed: () => void
}

function CancelModal({ isOpen, onCancelClose, onSucceed }: CancelModalProps) {
  const { t } = useTranslation()

  return (
    <InformationCard isOpen={isOpen} title='' close={onCancelClose}>
      <OuterContainer>
        <Icon iconName='OtherMotives' icSize='giant' icColor='_006BFF' />
        <InnerText variant='content-emphasis-07'>
          <SafeHtmlContent
            html={t('object-view.cancel-edit-modal.main-message')}
          />
        </InnerText>
        <InnerText variant='content-05'>
          <SafeHtmlContent
            html={t('object-view.cancel-edit-modal.help-message')}
          />
        </InnerText>
        <div className='container g-0 mt-4'>
          <div className='row'>
            <div className='col-6'>
              <Button variant='secondary' type='button' onClick={onCancelClose}>
                {t('object-view.cancel-edit-modal.return')}
              </Button>
            </div>
            <div className='col-6'>
              <Button variant='primary' type='button' onClick={onSucceed}>
                {t('object-view.cancel-edit-modal.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </OuterContainer>
    </InformationCard>
  )
}

export default CancelModal
