import { DocumentListCard, Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

import Button3D from './assets/3d-button.png'
import Button3DPressed from './assets/3d-button-pressed.png'

export const BaseCard = styled(DocumentListCard)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.colorSupportLightGrey};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
`

export const ActionLabel = styled(Text)`
  text-align: center;
`

export const CrazyButton = styled.button`
  width: 120px;
  height: 120px;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${Button3D});

  &:active {
    background-image: url(${Button3DPressed});
  }
`
