import { Button, Icon, SyntheticButton } from '@yes.technology/react-toolkit'

import { useTranslation } from 'react-i18n-lite'

import usePersistentSelection from '../../hooks/usePersistentSelection'

import { GroupedFieldValues } from '../../types'

import { Actions, Container } from './Field.styles'

type RelationDataFieldProps = {
  objectclassUuid: string
  name?: string
  children: React.ReactNode
  groupedFieldValues: GroupedFieldValues
  displayActions?: boolean
}

const RelationDataField = ({
  name,
  objectclassUuid,
  groupedFieldValues,
  displayActions,
  children
}: RelationDataFieldProps) => {
  const { getRedirectToObjectclassCallback } = usePersistentSelection()

  const selectAndLink = getRedirectToObjectclassCallback(
    name,
    objectclassUuid,
    groupedFieldValues
  )

  const { t } = useTranslation()

  const actionButtons = [
    {
      children: <Icon iconName='SelectAndRelateSign' />,
      title: t('select-and-link.action'),
      onClick: selectAndLink
    }
  ]

  return (
    <Container {...{ displayActions }}>
      {children}
      {displayActions && (
        <Actions>
          {actionButtons.length === 1 ? (
            <Button
              variant='secondary'
              title={actionButtons[0].title}
              onClick={actionButtons[0].onClick}
              style={{ minWidth: '40px' }}
            >
              {actionButtons[0].children}
            </Button>
          ) : (
            <SyntheticButton
              options={actionButtons}
              heightClass='regular'
              dropdownLabel='options'
              variant='secondary'
            />
          )}
        </Actions>
      )}
    </Container>
  )
}

export default RelationDataField
