import {
  Objectclass,
  Pagination as PaginationType,
  SortOption,
  ScrollBar,
  YesObject
} from '@yes.technology/react-toolkit'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useNavigateMergingState from 'shared/hooks/useNavigateMergingState/useNavigateMergingState'
import { FieldSettings } from 'shared/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { useSiteState } from 'siteState/shared'
import useInitialStatus from 'status/shared/hooks/useInitialStatus'
import DataTableAreaLine from './DataTableAreaLine'
import Actions from './components/Actions'
import RenderEmbedded from './components/RenderEmbedded'
import TableActions from './components/TableActions/TableActions'
import TableBody from './components/TableBody'
import TableHeader from './components/TableHeader'
import useColumns from './hooks/useColumns'
import { TableContext, TableContextValue } from './hooks/useTableContext'
import useTableContextValue from './hooks/useTableContextValue'
import {
  ActionColumn,
  ActionColumnsPosition,
  ColumnLayout,
  HierarchyLevel,
  SelectionOption
} from './types'

export const defaultActionColumns = [
  {
    title: 'actions',
    component: Actions
  }
]

export type DataTableConfigProps = {
  layoutClassificationUuid?: string
  hierarchyLevel?: HierarchyLevel
  actionButton?: {
    type: 'view' | 'document' | null
    target: '_self' | '_blank'
  }
  hideRowActionButtons?: boolean
  hideMainCollapsibleContainer?: boolean
  hideSortButtons?: boolean
}

export type DataTableProps = DataTableConfigProps & {
  informational?: boolean
  embedded?: boolean
  idObjectClass: string
  fields: Objectclass['fields']
  objects: YesObject[]
  columns: string[]
  isLoading: boolean
  actionColumns?: ActionColumn[]
  actionColumnsPosition?: ActionColumnsPosition
  onSort: (newSortOptions: SortOption[]) => void
  columnsLayouts?: ColumnLayout[]
  sortOptions?: SortOption[]
  pagination?: Partial<PaginationType>
  totalObjects?: number
  setCurrentPage: (page: number) => void
  title?: string
  isShowTitle?: boolean
  isCollapsibleCard?: boolean
  isShowPDFReports?: boolean
  isShowXLSReport?: boolean
  layoutClassificationUuid?: string
  objectclassQueryString?: string
  displayLayoutSelection?: boolean
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  concatFields?: ConcatFields
  shouldResetObjectArea?: boolean
  setShouldResetObjectArea?: (newValue: boolean) => void
  style?: React.CSSProperties
  fieldSettings?: FieldSettings
  allowCollapsibleNavigationCardCustomColors?: boolean
} & Pick<
    TableContextValue,
    | 'objectclass'
    | 'mode'
    | 'setMode'
    | 'changedFieldValues'
    | 'setChangedFieldValues'
    | 'refetchObjects'
    | 'relationCounterpartField'
    | 'reportDownloadTriggered'
    | 'triggerReportDownload'
    | 'reportUrl'
    | 'customActionButtons'
  >

const emptyArray: Array<unknown> = []

const DataTable = ({
  idObjectClass,
  objectclass,
  fields,
  objects,
  refetchObjects,
  columns,
  isLoading,
  actionColumns = defaultActionColumns,
  actionColumnsPosition = 'last',
  onSort,
  columnsLayouts = emptyArray as ColumnLayout[],
  sortOptions = emptyArray as SortOption[],
  pagination,
  totalObjects,
  setCurrentPage,
  title = '',
  isShowTitle = true,
  isCollapsibleCard = false,
  layoutClassificationUuid,
  hierarchyLevel = 'level_2',
  actionButton,
  mode,
  setMode,
  changedFieldValues,
  setChangedFieldValues,
  relationCounterpartField,
  reportDownloadTriggered,
  triggerReportDownload,
  reportUrl,
  objectclassQueryString = 'uuid_objectclass',
  displayLayoutSelection = true,
  informational = false,
  embedded = false,
  navigationSliderBackgroundColor = '#004099',
  navigationSliderColor,
  concatFields,
  shouldResetObjectArea,
  setShouldResetObjectArea,
  hideMainCollapsibleContainer = false,
  hideRowActionButtons = false,
  hideSortButtons = false,
  style,
  fieldSettings,
  customActionButtons,
  allowCollapsibleNavigationCardCustomColors,
  isShowPDFReports,
  isShowXLSReport
}: DataTableProps) => {
  const [selectedRows, setSelectedRows] = useState<
    Record<string, SelectionOption>
  >({})
  const hasSelectionAction = !informational && mode === 'view'

  const showColumn =
    !embedded || actionButton?.type === 'document' || !informational

  const amountOfActionColumns =
    showColumn && !hideRowActionButtons
      ? actionColumns.length + (hasSelectionAction ? 1 : 0)
      : 0

  const {
    displayedColumns,
    currentColumnIndex,
    totalColumns,
    amountOfFixedColumns,
    onChangeColumnsPage,
    allColumns
  } = useColumns({
    columns,
    columnsLayouts,
    amountOfActionColumns
  })

  const tableContextValue = useTableContextValue({
    idObjectClass,
    objectclass,
    fields,
    actionColumns,
    actionColumnsPosition,
    columnsNames: displayedColumns,
    amountOfFixedColumns,
    totalColumns,
    objects,
    refetchObjects,
    columnsLayouts,
    onSort,
    sortOptions,
    currentColumnIndex,
    pagination,
    totalObjects,
    layoutClassificationUuid,
    hierarchyLevel,
    actionButton,
    selectedRows,
    setSelectedRows,
    mode,
    setMode,
    changedFieldValues,
    setChangedFieldValues,
    relationCounterpartField,
    reportDownloadTriggered,
    triggerReportDownload,
    reportUrl,
    objectclassQueryString,
    displayLayoutSelection,
    informational,
    embedded,
    navigationSliderBackgroundColor,
    navigationSliderColor,
    allowCollapsibleNavigationCardCustomColors,
    concatFields,
    hideRowActionButtons,
    hideSortButtons,
    onChangeColumnsPage,
    amountOfActionColumns,
    customActionButtons,
    allColumns,
    isShowPDFReports,
    isShowXLSReport
  })

  const initialStatus = useInitialStatus({ objectclassUuid: idObjectClass })

  const navigateMergingState = useNavigateMergingState()

  const [_, setObjectIndex] = useSiteState<number | undefined>('object-index')
  const [lockObjectViewObject] = useSiteState<boolean>(
    'lock-object-view-object'
  )

  const paginationOffset = pagination?.offset || 0

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const handleSelectedObjectNavigation = () => {
      searchParams.set('object-uuid', objects[0].uuid)
      searchParams.set(objectclassQueryString, idObjectClass)

      const search = `?${searchParams.toString()}`

      setObjectIndex(paginationOffset)

      setShouldResetObjectArea?.(false)

      navigateMergingState(
        {
          search,
          hash: ''
        },
        {
          state: {
            sortOptions
          },
          replace: true
        }
      )
    }
    if (
      lockObjectViewObject ||
      hideRowActionButtons ||
      objects.length === 0 ||
      !shouldResetObjectArea
    )
      return

    handleSelectedObjectNavigation()
  }, [
    lockObjectViewObject,
    objects,
    navigateMergingState,
    objectclassQueryString,
    idObjectClass,
    sortOptions,
    embedded,
    setObjectIndex,
    shouldResetObjectArea,
    setShouldResetObjectArea,
    hideRowActionButtons,
    paginationOffset,
    searchParams
  ])

  return (
    <TableContext.Provider value={tableContextValue}>
      <DataTableAreaLine
        {...{
          hierarchyLevel,
          title,
          isShowTitle,
          isCollapsibleCard,
          navigationSliderBackgroundColor,
          navigationSliderColor,
          pagination,
          setCurrentPage,
          embedded,
          hideMainCollapsibleContainer,
          allowCollapsibleNavigationCardCustomColors
        }}
        aria-busy={isLoading}
        aria-live='assertive'
        style={style}
      >
        <RenderEmbedded className='pb-4' embedded={embedded}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='table-responsive'>
                <ScrollBar scrollMode='horizontal'>
                  {!informational && !isLoading && (
                    <TableActions
                      {...{
                        layoutClassificationUuid,
                        initialStatus
                      }}
                    />
                  )}
                  <table className='table'>
                    <TableHeader {...{ isLoading }} />
                    <TableBody {...{ isLoading, fieldSettings }} />
                  </table>
                </ScrollBar>
              </div>
            </div>
          </div>
        </RenderEmbedded>
      </DataTableAreaLine>
    </TableContext.Provider>
  )
}

export default DataTable
