import { useId } from 'react'
import useTableContext from '../../hooks/useTableContext'
import { AutocompleteUniselection, Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import {
  LayoutAutocompleteWrapper,
  FileExportActions,
  LayoutContainer
} from './LayoutAndReport.styles'
import useExportXLS from '../../hooks/useXLSData/useExportXLS'
import DownloaderLoading from 'report/components/DownloaderLoading'

export default function LayoutAndReport() {
  const {
    mode,
    triggerReportDownload,
    reportUrl,
    displayLayoutSelection,
    isShowPDFReports,
    isShowXLSReport
  } = useTableContext()

  const { t } = useTranslation()

  const layoutSelectionLabelId = useId()

  const reportDownloadClasses = 'btn btn-link p-0'

  const { exportXLSReport, isLoading } = useExportXLS()

  if (!isShowPDFReports && !isShowXLSReport) {
    return null
  }

  if (
    mode !== 'view' ||
    (!triggerReportDownload && !reportUrl && !displayLayoutSelection)
  ) {
    return null
  }

  if (isLoading) {
    return <DownloaderLoading />
  }

  return (
    <LayoutContainer>
      <FileExportActions>
        {isShowPDFReports && (
          <>
            {triggerReportDownload && !reportUrl && (
              <button
                type='button'
                className={reportDownloadClasses}
                onClick={triggerReportDownload}
                title={t('data-tables.download-pdf-report')}
              >
                <Icon iconName='PDFFile' icSize='large' icColor='sanJuan' />
              </button>
            )}
            {reportUrl && (
              <a
                className={reportDownloadClasses}
                href={reportUrl}
                download='report.pdf'
                title={t('data-tables.download-pdf-report')}
              >
                <Icon iconName='PDFFile' icSize='large' icColor='sanJuan' />
              </a>
            )}
          </>
        )}

        {isShowXLSReport && (
          <button
            type='button'
            className={reportDownloadClasses}
            onClick={exportXLSReport}
            title={t('data-tables.download-xls-report')}
          >
            <Icon iconName='ExcelFile' icSize='large' icColor='sanJuan' />
          </button>
        )}
      </FileExportActions>

      {displayLayoutSelection && (
        <LayoutAutocompleteWrapper>
          <div className='navigation-slider-title' id={layoutSelectionLabelId}>
            Layout
          </div>

          <AutocompleteUniselection
            aria-labelledby={layoutSelectionLabelId}
            options={[]}
            heightClass='semiSmall'
            disabled
            value=''
          />
        </LayoutAutocompleteWrapper>
      )}
    </LayoutContainer>
  )
}
