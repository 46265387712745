import { getSubdomain } from 'tldts'

export function getApplicationSystemInfo() {
  const { applicationSystemSubdomains, fallbackLogoutRedirectUrl } = window

  const currentSubdomain = getSubdomain(window.location.href) || ''

  const applicationSystemSubdomain = applicationSystemSubdomains?.find(
    (object) => object.des === currentSubdomain
  )

  const owner =
    applicationSystemSubdomain?.subapplicationsystemowner ??
    applicationSystemSubdomain?.applicationsystemowner

  const isStringOwner = typeof owner === 'string'

  const logoutRedirectUrl = isStringOwner
    ? fallbackLogoutRedirectUrl
    : ((owner?.[0]?.logout_redirect_url as string) ?? fallbackLogoutRedirectUrl)

  return {
    ownerUuid: isStringOwner ? owner : owner?.[0]?.uuid,
    logoutRedirectUrl,
    ownerDes: !isStringOwner ? owner?.[0]?.des : '',
    logoForPDFReport: !isStringOwner
      ? (owner?.[0]?.logo_for_PDF_report as string)
      : ''
  }
}
