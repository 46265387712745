import { Document, Page } from '@react-pdf/renderer'
import FilterArea from './components/FilterArea/FilterArea'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from '../site/Renderer/components/FilterFields/types'
import CardsArea from './components/CardsArea/CardsArea'
import { Field, Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { RelationsTableLayouts } from './hooks/useRelationsTableLayouts/useRelationsTableLayouts'
import ApplicationSystemTopLine from './components/ApplicationSystemTopLine'
import { TranslationContainer } from 'react-i18n-lite'
import locales from 'locales'
import { FieldSettings } from 'shared/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'

interface ReportProps {
  fields?: FilterFieldsResponse
  filterComposition: string
  objectFields: Field[]
  relationClasses: Field[]
  values: GroupedFieldValues
  objects: YesObject[]
  title: string
  objectclass?: Objectclass
  relationsTableLayouts?: RelationsTableLayouts
  filteractionName?: string
  fieldSettings?: FieldSettings
  concatFields?: ConcatFields
  relationConfig?: RelationConfigProps
}

function CardsReport({
  fields,
  filterComposition,
  values,
  title,
  objects,
  objectFields,
  relationClasses,
  objectclass,
  relationsTableLayouts,
  filteractionName,
  fieldSettings,
  concatFields,
  relationConfig
}: ReportProps) {
  return (
    <Document>
      <TranslationContainer locales={locales} defaultLanguage='pt-BR'>
        <Page size='A4' dpi={150}>
          <ApplicationSystemTopLine />
          {fields && (
            <FilterArea
              values={values}
              fields={fields}
              title={title}
              composition={filterComposition}
              filteractionName={filteractionName}
            />
          )}
          {objectclass && (
            <CardsArea
              objectclass={objectclass}
              objects={objects}
              fields={objectFields}
              relationClasses={relationClasses}
              relationsTableLayouts={relationsTableLayouts}
              fieldSettings={fieldSettings}
              concatFields={concatFields}
              relationConfig={relationConfig}
            />
          )}
        </Page>
      </TranslationContainer>
    </Document>
  )
}

export default CardsReport
