import { useEffect } from 'react'

import { useLogout } from 'shared/hooks'

const LoggingOut = () => {
  const { handleLoggingOut } = useLogout()

  useEffect(() => {
    handleLoggingOut()
    window.location.replace('/logged-out')
  }, [handleLoggingOut])

  return null
}

export default LoggingOut
