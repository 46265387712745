import { Style } from '@react-pdf/types'

const header = (
  backgroundColor: string,
  height: number,
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
): Style => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  height,
  backgroundColor,
  justifyContent: justifyContent || 'flex-start'
})

const title = (
  color: string,
  fontSize: number,
  fontWeight?: 'normal' | 600
): Style => ({
  color,
  fontSize,
  fontFamily: 'Barlow',
  fontWeight: fontWeight || 600
})

export default function getStyles(
  backgroundColor: string,
  height: number,
  color: string,
  fontSize: number,
  justifyContent?: 'flex-start' | 'center' | 'flex-end',
  fontWeight?: 'normal' | 600
) {
  return {
    header: header(backgroundColor, height, justifyContent),
    title: title(color, fontSize, fontWeight)
  }
}
