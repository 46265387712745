import { Image, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18n-lite'
import { getApplicationSystemInfo } from 'shared/utils'
import getStyles from './styles'

const ApplicationSystemTopLine = () => {
  const styles = getStyles()
  const { logoForPDFReport, ownerDes } = getApplicationSystemInfo()
  const { t } = useTranslation()
  return (
    <View style={styles.header}>
      {logoForPDFReport && <Image src={logoForPDFReport} style={styles.logo} />}
      <Text style={styles.title}>
        {t('report.title')} {ownerDes && `| ${ownerDes}`}
      </Text>
    </View>
  )
}

export default ApplicationSystemTopLine
