import { useEffect } from 'react'

import { useLogout } from 'shared/hooks'
import { getApplicationSystemInfo } from 'shared/utils'

const LoggedOut = () => {
  const { handleLoggedOut } = useLogout()

  const { logoutRedirectUrl } = getApplicationSystemInfo()

  useEffect(() => {
    handleLoggedOut()
    window.location.replace(logoutRedirectUrl)
  }, [handleLoggedOut, logoutRedirectUrl])

  return null
}

export default LoggedOut
