import { useSelector } from 'react-redux'
import { WorkflowmodelContainerProps } from './WorkflowmodelContainer.types'
import { AuthenticationType } from 'authentication/shared/types'
import Iframe from 'shared/components/Iframe'
import { useTranslation } from 'react-i18n-lite'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import useClientTracking from 'shared/hooks/useClientTracking'
import { useIframe } from 'shared/hooks'

type WorkflowEventData = {
  workflowUuid: string
}

function generateIframeUrl(
  workflowmodelUuid: string,
  workflowengineUrl: string,
  iframeQueryString: string,
  workflowUuid?: string
) {
  const baseUrl = workflowUuid
    ? `${workflowengineUrl}workflow/${workflowUuid}`
    : `${workflowengineUrl}workflowmodel/new/${workflowmodelUuid}`

  const fullUrl = `${baseUrl}?${iframeQueryString}`

  return fullUrl
}

const WorkflowmodelContainer = ({ props }: WorkflowmodelContainerProps) => {
  const { language } = useTranslation()

  const styleProps = useStyleMaps(props.styleMap)

  const [searchParams] = useSearchParams()
  const workflowUuid = searchParams.get('workflowUuid') || undefined

  let dataContainerQueryString = ''
  const denyKeys = ['workflowUuid', 'workflowmodelUuid']
  searchParams.forEach((value, key) => {
    if (!denyKeys.includes(key)) {
      dataContainerQueryString = `${dataContainerQueryString}&${key}=${value}`
    }
  })

  const { workflowmodelUuid } = props

  const token = useSelector(
    ({ authentication }: AuthenticationType) => authentication.token
  )

  const workflowengineUrl = window.waWorkflowengineUrl

  const { clientTracking, clearClientTracking } = useClientTracking()

  const iframeQueryString = `token=${token}&lang=${language}&clientTracking=${JSON.stringify(clientTracking)}${dataContainerQueryString}`

  const iframeUrl = generateIframeUrl(
    workflowmodelUuid || '',
    workflowengineUrl,
    iframeQueryString,
    workflowUuid
  )

  const { iframeAttributes, receiveMessage } = useIframe({
    origin: iframeUrl,
    src: iframeUrl
  })

  const onNewWorkflow = useCallback(
    (eventData: WorkflowEventData) => {
      const currentUrl = new URL(window.location.href)

      currentUrl.searchParams.delete('workflowmodelUuid')
      currentUrl.searchParams.set('workflowUuid', eventData.workflowUuid)

      window.history.replaceState(window.history.state, '', currentUrl.href)
      clearClientTracking()
    },
    [clearClientTracking]
  )

  const onReceivedMessage = useCallback(
    (event: MessageEvent) => {
      event.data.workflowUuid && onNewWorkflow(event.data)
      receiveMessage(event)
    },
    [onNewWorkflow, receiveMessage]
  )

  useEffect(() => {
    window.addEventListener('message', onReceivedMessage)
    return () => window.removeEventListener('message', onReceivedMessage)
  }, [onReceivedMessage])

  return (
    <>
      {(workflowmodelUuid || workflowUuid) && iframeAttributes ? (
        <Iframe
          key={iframeAttributes.src}
          {...iframeAttributes}
          style={styleProps}
        />
      ) : null}
    </>
  )
}

export default WorkflowmodelContainer
