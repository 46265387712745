import { Button } from '@yes.technology/react-toolkit'
import { HeightClass } from 'shared/types/toolkit'
import styled from 'styled-components'
import { tabsComponentSizes } from './Tabs.enum'

export const Container = styled.div<{ heightClass: HeightClass }>`
  display: inline-flex;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  gap: ${({ heightClass }) => tabsComponentSizes[heightClass].spaceBetween}px;
  align-items: center;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 358px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    max-width: 712px;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    max-width: 1232px;
  }
  @media (min-width: 1401px) {
    max-width: 1856px;
  }
`

export const InnerContainer = styled.div<{
  heightClass: HeightClass
}>`
  display: inline-flex;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  column-gap: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].spaceBetween}px;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`

export const SliderButton = styled(Button)<{ heightClass: HeightClass }>`
  background-color: transparent;
  border-radius: 100%;
  height: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].navigationButtonSize}px;
  width: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].navigationButtonSize}px;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border-color: #303032;

  > figure {
    width: 100%;
    height: 100%;
    > svg > path:first-child {
      display: none;
    }
  }

  &:disabled {
    border-color: #abbed1;
  }
`
