import styled from 'styled-components'

export const StyledCardWrapper = styled.div`
  height: auto;

  header {
    display: flex;
    justify-content: flex-end;
  }
  a {
    height: max-content;
    outline: none;
  }
  header a {
    max-width: 128px;
  }
  header img {
    width: 71px;
    height: 71px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    flex: none;
  }

  div[role='group'] {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin-top: 0.5rem;

    @media (min-width: 576px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 0.25rem), 1fr));
    }
  }

  @media (min-width: 768px) {
    height: 248px;
  }
`

export const StyledAccordionCard = styled(StyledCardWrapper)`
  height: auto;

  > div > div {
    min-height: 248px;
  }
  section {
    position: relative;
  }
  figure {
    position: absolute;
    top: 0;
    right: -8px;
    &:focus {
      outline: none !important;
    }
  }
  summary {
    padding: 0;
    outline: none;
  }
  summary > div > div {
    border: 0;
    padding: 0;
  }
  hr {
    display: none;
  }
  header {
    padding-right: 24px;
  }
`
