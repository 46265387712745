import { useSanitizeHtml } from 'site/shared/hooks/useSanitizeHtml'

interface HtmlContentProps {
  html: string
}

const SafeHtmlContent = ({ html }: HtmlContentProps) => {
  const sanitizeCallback = useSanitizeHtml()

  const sanitizedHtml = sanitizeCallback(html)

  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
}

export default SafeHtmlContent
