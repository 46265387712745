import { useCallback, useEffect, useMemo } from 'react'

export default function useClientTracking({
  trackingObjectclassUuid,
  objectUuid
}: {
  trackingObjectclassUuid?: string
  objectUuid?: string
} = {}) {
  useEffect(() => {
    if (!trackingObjectclassUuid || !objectUuid) return

    const storedClientTracking = JSON.parse(
      sessionStorage.getItem('clientTracking') || '{}'
    )

    const updatedTracking = {
      ...storedClientTracking,
      [trackingObjectclassUuid]: {
        uuid: objectUuid,
        timestamp: new Date().toISOString()
      }
    }

    sessionStorage.setItem('clientTracking', JSON.stringify(updatedTracking))
  }, [trackingObjectclassUuid, objectUuid])

  const trackingInStorage = sessionStorage.getItem('clientTracking') || '{}'

  const clientTracking = useMemo(
    () => JSON.parse(trackingInStorage),
    [trackingInStorage]
  )

  const clearClientTracking = useCallback(() => {
    sessionStorage.removeItem('clientTracking')
  }, [])

  return { clientTracking, clearClientTracking }
}
