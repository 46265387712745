import { Divider as ToolkitDivider } from '@yes.technology/react-toolkit'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { DividerProps } from './Divider.types'

const Divider = ({ props }: DividerProps) => {
  const {
    heightClass = 'extraSmall',
    spacingClass = 'regular',
    color = 'mediumGray',
    orientation = 'horizontal',
    styleMap = {}
  } = props
  const styleProps = useStyleMaps(styleMap)
  return (
    <ToolkitDivider
      heightClass={heightClass}
      spacingClass={spacingClass}
      color={color}
      orientation={orientation}
      style={styleProps}
    />
  )
}
export default Divider
