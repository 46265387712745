import { DataFieldSelection, Objectclass } from '@yes.technology/react-toolkit'
import { ComponentProps } from 'react'

import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import { ArrayFieldValue } from '../../types'
import { SelectionMenuOption } from './StandardMultiselectionField'
import {
  SelectionOption,
  parseObjectsToOptions,
  parseOptionsToObjects
} from './optionsParser'

type StandardUniselectionProps = ComponentProps<typeof DataFieldSelection>

type AutocompleteUniselectionFieldProps = {
  onChangeValues: (values: ArrayFieldValue) => void
  selectedOptions: ArrayFieldValue
  inputRef?: React.Ref<HTMLInputElement>
  filteractionUuid: string
} & Partial<StandardUniselectionProps>

const StandardUniselectionField = ({
  inputRef,
  onChangeValues,
  selectedOptions: selectedObjects,
  filteractionUuid,
  ...props
}: AutocompleteUniselectionFieldProps) => {
  const [objectclass] = useSiteState<Objectclass | undefined>(
    'filter-objectclass'
  )

  const { yesObjects, isFetching } = useFetchYesObjects({
    filteractionUuid,
    initialPagination: { limit: 300, offset: 0 },
    showLoading: false,
    interpolationObject: objectclass,
    enabled: !!objectclass
  })

  const onSelect = (newSelectedOptions: SelectionMenuOption[]) => {
    if (!newSelectedOptions) return

    const parsedNewSelectedOptions = parseOptionsToObjects(
      newSelectedOptions as SelectionOption[]
    )

    const isDeselection =
      selectedObjects[0]?.uuid === parsedNewSelectedOptions[0]?.uuid

    if (isDeselection) {
      onChangeValues([])
      return
    }

    onChangeValues(parsedNewSelectedOptions)
  }

  if (typeof selectedObjects === 'string') {
    return null
  }

  const options = parseObjectsToOptions(yesObjects)
  const selectedOptions = parseObjectsToOptions(selectedObjects)

  return (
    <DataFieldSelection
      {...props}
      ref={inputRef}
      statusClass={isFetching ? 'processing' : 'default'}
      onChange={onSelect}
      selected={selectedOptions}
      options={options}
    />
  )
}

export default StandardUniselectionField
