import { View } from '@react-pdf/renderer'
import { Field, Objectclass, YesObject } from '@yes.technology/react-toolkit'
import ObjectCard from './components/ObjectCard/ObjectCard'
import { RelationsTableLayouts } from 'report/hooks/useRelationsTableLayouts/useRelationsTableLayouts'
import PDFAreaLine from '../PDFAreaLine'
import { FieldSettings } from 'shared/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'

interface CardsAreaProps {
  objectclass: Objectclass
  objects: YesObject[]
  fields: Field[]
  relationClasses: Field[]
  relationsTableLayouts?: RelationsTableLayouts
  fieldSettings?: FieldSettings
  concatFields?: ConcatFields
  relationConfig?: RelationConfigProps
}

function CardsArea({
  objectclass,
  fields,
  objects,
  relationClasses,
  relationsTableLayouts,
  fieldSettings,
  concatFields,
  relationConfig
}: CardsAreaProps) {
  return (
    <View>
      <PDFAreaLine title={objectclass.des} />

      {objects?.map((object, index, arr) => (
        <ObjectCard
          key={object.uuid}
          fields={fields}
          object={object}
          relationClasses={relationClasses}
          title='Objeto'
          relationsTableLayouts={relationsTableLayouts}
          relationConfig={relationConfig}
          concatFields={concatFields}
        />
      ))}
    </View>
  )
}

export default CardsArea
