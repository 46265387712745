import { isEmbedded, useParentPostMessage } from '@yes.technology/react-toolkit'
import { loginSucceeded } from 'authentication/Login/ducks/authentication'
import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { IframeAttributes } from 'shared/components/Iframe'
import { getApplicationSystemInfo } from 'shared/utils'

type OriginalSender = {
  source: string
}

type EventDataMessage = {
  height?: string
  type?: string
  url?: string
  token?: string
  expirationDate?: number
  originalSender: OriginalSender
}

type Props = {
  origin: string
  src: string
  title?: string
  allow?: string
  initialHeight?: string
  sharedState?: Record<string, any>
}

const useIframe = ({
  origin,
  src,
  title = 'Masterdata',
  allow = 'camera;geolocation',

  initialHeight = '800px', //  TODO: Alterar para 100vh quando a workflow engine passar a enviar o height por mensagem
  sharedState
}: Props) => {
  const [height, setHeight] = useState(initialHeight)
  const [iframeAttributes, setIframeAttributes] = useState<
    IframeAttributes | undefined
  >()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { postMessage } = useParentPostMessage()

  const redirectToUrl = useCallback(
    (url?: string) => {
      const absoluteUrlRegex = new RegExp('^(?:[a-z+]+:)?//', 'i')
      if (url) {
        absoluteUrlRegex.test(url)
          ? (window.location.href = url)
          : navigate(url)
      }
    },
    [navigate]
  )

  const receiveMessage = useCallback(
    (event: MessageEvent<EventDataMessage>) => {
      // TODO: Improve iframe recognition in case of two different iframes with same origin (URL =/= Origin)
      if (!origin.startsWith(event.origin)) return false
      const { height, type, originalSender } = event.data
      if (height) {
        setHeight(`${Math.ceil(Number(height) + 50)}`)
      }
      if (type === 'redirect') {
        if (isEmbedded()) {
          postMessage(event.data)
        } else {
          redirectToUrl(event.data.url)
        }
      }

      if (type === 'updateAuthToken') {
        const { token, expirationDate } = event.data
        if (!token || !expirationDate) return

        const decoded = jwtDecode<{ email: string }>(token)
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: expirationDate,
            tokenType: 'private'
          })
        )

        if (isEmbedded()) {
          postMessage(event.data)
        }
      }

      if (type === 'redirectAndUpdateAuthToken') {
        const { token, expirationDate } = event.data
        if (!token || !expirationDate) return

        const decoded = jwtDecode<{ email: string }>(token)
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: expirationDate,
            tokenType: 'private'
          })
        )

        if (isEmbedded()) {
          postMessage(event.data)
        } else {
          redirectToUrl(event.data.url)
        }
      }

      if (type === 'getSharedState') {
        const { ownerUuid } = getApplicationSystemInfo()

        const applicationSystemOwner = { owner: ownerUuid }

        event.source?.postMessage(
          {
            originalSender,
            type: 'patchSharedState',
            sharedState: { ...sharedState, ...applicationSystemOwner }
          },
          { targetOrigin: '*' }
        )
      }
    },
    [dispatch, origin, sharedState, redirectToUrl] // postMessage foi implementado sem useCallback por isso não foi adicionado aqui
  )

  useEffect(() => {
    setIframeAttributes({
      src,
      height,
      title,
      allow
    })
  }, [allow, height, src, title])

  return {
    iframeAttributes,
    receiveMessage
  }
}

export default useIframe
