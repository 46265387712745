import { Classification } from '@yes.technology/react-toolkit'
import { createContext, useContext } from 'react'

type KanbanContextValue = {
  onStatusChange: (taskUuid: string, newStatusColumn: string) => void
  statusActionClassification?: Classification[]
}

const defaultValue = {
  onStatusChange: () => undefined,
  statusActionClassification: Array<Classification>()
}

export const KanbanContext = createContext<KanbanContextValue>(defaultValue)

export default function useKanbanContext() {
  const contextValue = useContext(KanbanContext)

  return contextValue
}
