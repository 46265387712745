import { Text } from '@react-pdf/renderer'
import useFormattedValue, {
  FieldType
} from 'site/Renderer/components/DataTables/hooks/useFormattedValue'
import { CellValue } from 'site/Renderer/components/DataTables/types'
import styles from './styles'

export type PDFTableCellProps = {
  value: CellValue
  type: FieldType
}

export default function PDFTableCell({ value, type }: PDFTableCellProps) {
  const formattedValue = useFormattedValue({ value, type })

  return (
    <Text style={styles.tableBodyCell} wrap={false}>
      {formattedValue}
    </Text>
  )
}
