import { Text, View } from '@react-pdf/renderer'
import { Field, PDFCard, YesObject } from '@yes.technology/react-toolkit'
import getStyles from './styles'

interface SubCardProps {
  fields: Field[]
  object: YesObject
  title: string
}

function SubCard({ title, fields, object }: SubCardProps) {
  const styles = getStyles()

  const itemsPerRow = 6
  const rowsCount = Math.ceil(fields.length / itemsPerRow)

  const rows = Array.from({ length: rowsCount }, (_, index) => {
    const rowStartIndex = index * itemsPerRow
    const rowEndIndex = rowStartIndex + itemsPerRow

    return fields.slice(rowStartIndex, rowEndIndex)
  })

  return (
    <View style={styles.container}>
      <PDFCard style={styles.card} noGutters>
        <Text style={styles.title}>{title}</Text>

        {rows.map((row, rowIndex) => (
          <View wrap={false} key={rowIndex} style={styles.row}>
            {row.map((field) => (
              <View wrap={false} key={field.uuid} style={styles.datafield}>
                <Text style={styles.label}>{field.display_text.des}</Text>
                <Text
                  style={styles.input}
                >{`${object?.[field?.column_json] || ''}`}</Text>
              </View>
            ))}
          </View>
        ))}
      </PDFCard>
    </View>
  )
}

export default SubCard
