import {
  Button,
  DocumentListCard,
  SkeletonDots,
  SyntheticButton,
  Unifree,
  YesObject
} from '@yes.technology/react-toolkit'
import { ImpactClass } from 'shared/types/toolkit'
import patchObject from 'yesObject/shared/api/patchObject'
import useSituationWithActions from '../../hooks/useSituationWithActions'
import { useTranslation } from 'react-i18n-lite'

interface ShowSituationProps {
  situationCode?: string
  objectClassUUID: string
  objectVersion: string
  objectUUID: string
  mainActionLabel: string
  mainActionOnClick: () => void
  onRefresh?: () => void
  onEditCancel: () => void
  yesObject: YesObject
  informational?: boolean
  isEditing?: boolean
}

function ShowSituation({
  yesObject,
  situationCode,
  objectClassUUID,
  objectVersion,
  objectUUID,
  mainActionLabel,
  mainActionOnClick,
  onRefresh,
  onEditCancel,
  informational,
  isEditing
}: ShowSituationProps) {
  const { isLoading, objectSituation, visibleSituationActions } =
    useSituationWithActions({ situationCode })

  const { t } = useTranslation()

  const onActionClick = (destinationCode: string) => {
    patchObject({
      uuid: objectUUID,
      data: {
        uuid_Objectclass: objectClassUUID,
        situation: destinationCode,
        version: objectVersion
      },
      allowSpecialFields: true
    }).then(() => {
      onRefresh?.()
    })
  }

  const options = [
    {
      children: mainActionLabel,
      onClick: mainActionOnClick,
      impactClass: 'situationChange' as ImpactClass
    },
    ...visibleSituationActions.flatMap((action) =>
      action.destination_situation.length > 0
        ? {
            children: action.des,
            onClick: () => onActionClick(action.destination_situation[0].code),
            impactClass: 'situationChange' as ImpactClass
          }
        : []
    )
  ]

  const informationalClasses = 'col-md-12'

  const fieldClasses = isEditing ? 'col-12 col-xl-6' : 'col-12 col-xl-9'

  return (
    <div className='col-md-6'>
      <DocumentListCard className='p-2'>
        {isLoading && <SkeletonDots />}

        {!isLoading && (
          <div className='row align-items-end g-1'>
            {objectSituation && (
              <div
                className={informational ? informationalClasses : fieldClasses}
              >
                <Unifree
                  label={t('show-situation.brief')}
                  value={`${yesObject.sid} | ${objectSituation.des} | ${objectVersion}`}
                  disabled
                  required
                  readOnly={informational}
                />
              </div>
            )}

            {!informational && (
              <div className='col-12 col-xl-3'>
                <SyntheticButton
                  {...{ options }}
                  dropdownLabel={t('show-situation.situations')}
                  variant='primary'
                  heightClass='regular'
                />
              </div>
            )}

            {!informational && isEditing && (
              <div className='col-12 col-xl-3'>
                <Button variant='secondary' onClick={onEditCancel}>
                  {t('show-situation.cancel')}
                </Button>
              </div>
            )}
          </div>
        )}
      </DocumentListCard>
    </div>
  )
}

export default ShowSituation
