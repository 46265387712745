import { View } from '@react-pdf/renderer'
import { Field, PDFCard } from '@yes.technology/react-toolkit'
import React from 'react'
import { FieldValues } from 'site/Renderer/components/FilterFields/types'
import OperatorField from '../OperatorField/OperatorField'
import getStyles from './styles'
import FilterGroupChip from '../FilterGroupChip/FilterGroupChip'

interface FilterBodyProps {
  fields: Record<string, Field>
  values: FieldValues
  filterName: string
}

function FilterBody({ fields, values, filterName }: FilterBodyProps) {
  const styles = getStyles()
  return (
    <PDFCard style={styles.bodyCard}>
      <View style={{ position: 'relative', top: -20 }}>
        <FilterGroupChip text={filterName} />
      </View>
      <View style={styles.bodyContainer}>
        {Object.entries(values).map(([key, item]) => {
          const label = fields[key]?.display_text.des
            ? fields[key].display_text.des
            : 'not-configured'
          return (
            <View key={key} style={styles.item}>
              <OperatorField
                label={label}
                operator={item.operator}
                value={
                  Array.isArray(item.value)
                    ? item.value.map((value) => value.des)
                    : item.value
                }
              />
            </View>
          )
        })}
      </View>
    </PDFCard>
  )
}

export default FilterBody
