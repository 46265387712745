import { Routes, Route } from 'react-router-dom'

import { PrivateRoutes } from 'infrastructure/shared/components'
import NotFoundPage from 'infrastructure/NotFoundPage'
import Create from './Create'
import { LoggingOut, LoggedOut } from 'authentication/Logout'

const SiteRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* No support for optional parameters in v6 */}
        {/* https://reactrouter.com/docs/en/v6/faq#what-happened-to-regexp-routes-paths */}
        <Route index element={<Create />} />
        <Route path=':sitemodelUuid' element={<Create />} />
        <Route path='masterdata/:objectclassUuid' element={<Create />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>

      <Route path='/logout' element={<LoggingOut />} />
      <Route path='/logged-out' element={<LoggedOut />} />
    </Routes>
  )
}

export default SiteRoutes
