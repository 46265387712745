import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  position: relative;
`

export const Button = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: none;
`
