import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'
import {
  DEFAULT_TABLE_LAYOUT,
  fetchTableLayouts,
  TableLayout
} from 'site/Renderer/components/DataTables/hooks/useTableLayouts'

export type RelationsTableLayouts = {
  [relationClassUuid: string]: TableLayout
}

async function fetchRelationsTableLayouts(
  relationsConfig?: RelationConfigProps
) {
  if (!relationsConfig) return {} as RelationsTableLayouts

  const relationsTableLayouts: RelationsTableLayouts = {}

  for (const [relationClassUuid, relationConfig] of Object.entries(
    relationsConfig
  )) {
    const tableLayouts = await fetchTableLayouts(
      relationConfig.uuid_layout_classification
    )
    relationsTableLayouts[relationClassUuid] =
      tableLayouts[0] || DEFAULT_TABLE_LAYOUT[0]
  }

  return relationsTableLayouts
}

const useRelationsTableLayouts = ({
  relationConfig: relationsConfig
}: {
  relationConfig?: RelationConfigProps
}) => {
  const { data, error, isFetching } = useQuery<RelationsTableLayouts, Error>({
    queryKey: ['tableLayouts', relationsConfig],
    queryFn: async () => fetchRelationsTableLayouts(relationsConfig),
    enabled: !!relationsConfig
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return {
    relationsTableLayouts: data || {},
    isFetching
  }
}

export default useRelationsTableLayouts
