import { Style } from '@react-pdf/types'

const container: Style = {
  flexDirection: 'column',
  width: '100%',
  padding: 4,
  gap: 4
}

const section: Style = {
  flexDirection: 'column',
  padding: 0
}

const tableHeadCell: Style = {
  paddingTop: 20,
  paddingBottom: 4,

  fontSize: 8,
  fontFamily: 'Barlow',
  fontWeight: 600,
  color: '#303032',

  flexDirection: 'row',
  alignItems: 'flex-end',
  borderBottom: 1,
  borderColor: '#ABBED1',

  width: '100%'
}

const tableBodyCell: Style = {
  padding: 8,
  minHeight: 20,
  alignItems: 'center',

  fontSize: 8,
  fontFamily: 'Barlow',
  fontWeight: 400,
  color: '#303032',

  width: '100%'
}

const table: Style = {
  flexDirection: 'column',
  paddingHorizontal: 4,
  gap: 4
}

const tableRow: Style = {
  flexDirection: 'row',
  gap: 4
}

const evenRow: Style = {
  backgroundColor: '#F7F8FA'
}

const areaLine: Style = {
  paddingHorizontal: 4,
  flexDirection: 'row',
  alignItems: 'center',
  height: 20
}

const areaLineTitle: Style = {
  fontSize: 10,
  fontFamily: 'Barlow',
  fontWeight: 600,
  color: '#000000'
}

export default {
  container,
  section,
  table,
  tableRow,
  tableHeadCell,
  tableBodyCell,
  evenRow,
  areaLine,
  areaLineTitle
}
