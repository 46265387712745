import { useEffect } from 'react'

import { ColumnLayout } from '../types'
import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'
import { useQuery } from '@tanstack/react-query'

type RawLayout = {
  uuid: string
  des: string
  layout_content: string
}

export type TableLayout = {
  uuid: string
  des: string
  defaultOrderBy: [string, string][]
  columnsLayouts: ColumnLayout[]
  removeColumns: string[]
}

type RawColumnLayout = {
  width: string
  text_wrapping: boolean
  freeze: boolean
  field_names: string[]
}

type RawLayoutContent = {
  field_order_by: [string, string][]
  visible_columns: RawColumnLayout[]
  remove_columns?: string[]
}

const defaultTableLayouts = [
  // {
  //   uuid: '31e39738-4d76-11ec-81d3-0242ac130004',
  //   des: 'Simple layout 2',
  //   layout_content: JSON.stringify({
  //     field_order_by: [
  //       ['uuid', 'asc'],
  //       ['des', 'asc']
  //     ],
  //     visible_columns: [
  //       {
  //         freeze: true,
  //         field_names: ['des']
  //       },
  //       {
  //         field_names: ['complement']
  //       },
  //       {
  //         field_names: ['situation']
  //       },
  //       {
  //         field_names: ['date_creation_row']
  //       },
  //       {
  //         field_names: ['uuid']
  //       }
  //     ]
  //   })
  // },
  {
    uuid: 'c938d10e-ec00-421d-9de9-0efe6fb33b1a',
    des: 'Layout para relation',
    layout_content: JSON.stringify({
      field_order_by: [['des', 'asc']],
      visible_columns: [
        { freeze: true, field_names: ['des'] },
        { field_names: ['sid'] }
      ]
    })
  }
]

const formatTableLayouts = (layouts: RawLayout[]) =>
  layouts.map<TableLayout>((layout) => {
    const layoutContent = JSON.parse(layout.layout_content) as RawLayoutContent

    return {
      uuid: layout.uuid,
      des: layout.des,
      removeColumns: layoutContent?.remove_columns || [],
      defaultOrderBy: layoutContent.field_order_by,
      columnsLayouts: layoutContent.visible_columns.map<ColumnLayout>(
        (column) => ({ ...column, name: column.field_names[0] })
      )
    } as TableLayout
  })

export const DEFAULT_TABLE_LAYOUT = formatTableLayouts(defaultTableLayouts)

export async function fetchTableLayouts(
  layoutClassificationUuid: string | undefined
) {
  const res = await fetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            'situation.des': {
              $eq: '40'
            },
            'uuid.des': {
              $eq: layoutClassificationUuid
            }
          }
        ]
      },
      objectclass: 'UUID_Objectclass_Classiclistlayout',
      pagination: {
        offset: 0,
        limit: 10
      },
      orderby: [
        {
          des: 'asc'
        }
      ]
    }
  })
  return formatTableLayouts(res.objects as any[])
}

const useTableLayouts = ({
  layoutClassificationUuid
}: {
  layoutClassificationUuid?: string | undefined
}) => {
  const { data, error } = useQuery<TableLayout[], Error>({
    queryKey: ['tableLayouts', layoutClassificationUuid],
    queryFn: async () => fetchTableLayouts(layoutClassificationUuid),
    enabled: !!layoutClassificationUuid
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return data || DEFAULT_TABLE_LAYOUT
}

export default useTableLayouts
