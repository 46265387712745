import { useMemo } from 'react'

import { SitemodelContextValue } from 'sitemodel/shared/types'
import LoadComponent from './LoadComponent'
import { Areas, Component } from 'site/shared/types'
import {
  Automation,
  useAutomation,
  useSitemodelContentAggregator
} from 'sitemodel/shared/hooks'
import { SitemodelManifestStateGrouped } from 'sitemodel/shared/types/sitemodel.type'
import ScrollToTop from 'shared/utils/router/ScrollToTop'

type RendererProps = SitemodelContextValue & {
  areas?: Areas[]
  components?: Component[]
  state?: SitemodelManifestStateGrouped['filteraction']
  automations?: Automation[]
}

const Renderer = (props: RendererProps) => {
  const { areas, components } = props
  const componentsToRender = useMemo(
    () => areas || components,
    [areas, components]
  )

  const state = useMemo(() => props.state || {}, [props.state])

  const automations = useMemo(
    () => props.automations || [],
    [props.automations]
  )

  const { isAutomationRunning } = useAutomation(automations)

  useSitemodelContentAggregator(state)

  if (!componentsToRender || !state || isAutomationRunning) return null

  return (
    <>
      <ScrollToTop />
      <LoadComponent components={componentsToRender} />
    </>
  )
}

export default Renderer
