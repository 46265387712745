import { sortByKey } from 'shared/utils/array/sort'
import { Automation, RedirectAutomation } from './automation.types'
import { useEffect, useState } from 'react'
import useRedirect from './useRedirect'

const useAutomation = (automations: Automation[]) => {
  const [isAutomationRunning, setIsAutomationRunning] = useState(false)

  const { stateWithOneObject } = useRedirect()

  useEffect(() => {
    if (!automations?.length) return

    setIsAutomationRunning(true)

    const sortedAutomations = sortByKey(automations, 'order')

    const executeRedirectAutomation = (automation: RedirectAutomation) => {
      if (automation.trigger === 'state-with-one-object') {
        const { state_identifier } = automation.automation_data
        stateWithOneObject(state_identifier, () =>
          setIsAutomationRunning(false)
        )
      }
    }

    for (const automation of sortedAutomations) {
      switch (automation.automation_type) {
        case 'redirect': {
          executeRedirectAutomation(automation)
          break
        }
      }
    }
  }, [automations, stateWithOneObject])

  return { isAutomationRunning }
}

export default useAutomation
