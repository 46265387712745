import { Field, PDFCard, YesObject } from '@yes.technology/react-toolkit'
import getStyles from './styles'
import SubCard from '../SubCard/SubCard'
import RelationDataTable from '../RelationDataTable'
import { RelationsTableLayouts } from 'report/hooks/useRelationsTableLayouts/useRelationsTableLayouts'
import PDFAreaLine from 'report/components/PDFAreaLine'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'
import { ConcatFields } from 'shared/utils/object/concatenateFields'

interface ObjectCardProps {
  fields: Field[]
  relationClasses: Field[]
  object: YesObject
  title: string
  relationsTableLayouts?: RelationsTableLayouts
  relationConfig?: RelationConfigProps
  concatFields?: ConcatFields
}

function ObjectCard({
  object,
  title,
  fields,
  relationClasses,
  relationsTableLayouts,
  relationConfig,
  concatFields
}: ObjectCardProps) {
  const styles = getStyles()
  const uuidsRelationClassesWithLayouts = Object.keys(
    relationsTableLayouts || {}
  )

  const relationClassesWithLayouts = relationClasses.filter((relationClass) =>
    uuidsRelationClassesWithLayouts.includes(relationClass.uuid)
  )

  const displayedRelationClasses = uuidsRelationClassesWithLayouts.length
    ? relationClassesWithLayouts
    : relationClasses

  return (
    <PDFCard style={styles.mainCard} noGutters>
      <PDFAreaLine title={title} justifyContent='center' />

      <SubCard title='Dados gerais' fields={fields} object={object} />

      {displayedRelationClasses?.map((relationClass) => {
        const fields = Object.values(
          relationClass.relation?.object_class?.fields || {}
        )

        const columns = fields.map((field) => field.column_json)

        return (
          object?.[relationClass.column_json] && (
            <RelationDataTable
              key={relationClass.uuid}
              columns={columns}
              fields={relationClass.relation?.object_class?.fields || {}}
              objects={object[relationClass.column_json] as YesObject[]}
              relationName={relationClass.display_text.des || ''}
              tableLayout={relationsTableLayouts?.[relationClass.uuid]}
              concatFields={concatFields}
            />
          )
        )
      })}
    </PDFCard>
  )
}

export default ObjectCard
