import { useEffect } from 'react'
import DocumentDownloader from './components/DocumentDownloader/DocumentDownloader'
import useSplitReportComponents from './hooks/useSplitReportComponents/useSplitReportComponents'
import useReportPdfs from './hooks/useReportPdfs/useReportPdfs'
import useReportData from './hooks/useReportData/useReportData'
import useReportZip from './hooks/useReportZip/useReportZip'
import { YesObject } from '@yes.technology/react-toolkit'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'
import { FieldSettings } from 'shared/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'

type ReportProps = {
  reportType: 'list' | 'card'
  downloadType: 'single-pdf' | 'multiple-pdf-zip'
  yesObject?: YesObject
  layoutClassificationUuid?: string
  filteractionUuid?: string
  splitThreshold?: number
  onUrlReady?: (url: string) => void
  relationConfig?: RelationConfigProps
  fieldSettings?: FieldSettings
  concatFields?: ConcatFields
  classificationUuid?: string
}

export default function Report({
  splitThreshold = 10,
  layoutClassificationUuid,
  filteractionUuid,
  reportType,
  downloadType,
  yesObject,
  onUrlReady,
  relationConfig,
  fieldSettings,
  concatFields,
  classificationUuid
}: ReportProps) {
  const {
    objectFields,
    fieldValues,
    filterComposition,
    filterFields,
    yesObjects: filterYesObjects,
    requestLoading,
    relationsTableLayouts,
    filteractionName
  } = useReportData({
    filteractionUuid,
    relationConfig,
    fieldSettings,
    classificationUuid
  })

  const yesObjects = yesObject ? [yesObject] : filterYesObjects

  const reportComponents = useSplitReportComponents({
    ...objectFields,
    fieldValues,
    filterComposition,
    filterFields,
    reportType,
    layoutClassificationUuid,
    splitThreshold,
    yesObjects,
    filteractionName,
    relationsTableLayouts,
    isLoading: requestLoading,
    concatFields,
    relationConfig,
    fieldSettings
  })

  const shouldMerge = downloadType !== 'multiple-pdf-zip'

  const { urls, blobs, isRendering } = useReportPdfs({
    components: reportComponents,
    sourceObjects: yesObjects,
    shouldMerge,
    isLoading: requestLoading
  })

  const { url: zipUrl, isPacking } = useReportZip({
    blobs,
    sourceObjects: yesObjects,
    enabled: downloadType !== 'single-pdf'
  })

  const url = zipUrl || (downloadType === 'single-pdf' && urls[0]) || null

  useEffect(() => {
    if (url) {
      onUrlReady?.(url)
    }
  }, [url, onUrlReady])

  const filename = downloadType === 'single-pdf' ? 'report.pdf' : 'report.zip'

  return (
    <DocumentDownloader
      {...{ url, filename }}
      loading={isRendering || isPacking || requestLoading}
    />
  )
}
