import { useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18n-lite'
import useFetchYesObjects from './useFetchYesObjects'
import {
  ProjectFields,
  YesObject,
  useDisplayUserError
} from '@yes.technology/react-toolkit'

type UseFetchYesObjectsArgs = {
  objectUUID?: string
  objectSID?: string
  objectclassUUID: string
  showLoading?: boolean
  skipRelations?: boolean
  skipMetadata?: boolean
  projectFields?: ProjectFields
  displayMessageOnNoItemsFound?: boolean
  enabled?: boolean
}

export default function useYesObject<YesObjectType extends YesObject>({
  objectUUID,
  objectSID,
  objectclassUUID,
  showLoading = false,
  skipRelations = false,
  skipMetadata = false,
  projectFields,
  displayMessageOnNoItemsFound = true,
  enabled = true
}: UseFetchYesObjectsArgs) {
  const { t } = useTranslation()

  const { displayUserError } = useDisplayUserError()

  const filterBody = useMemo(
    () => ({
      query: {
        $and: [
          {
            ...(objectUUID && {
              'uuid.des': {
                $eq: objectUUID
              }
            }),
            ...(objectSID && {
              'sid.des': {
                $eq: objectSID
              }
            })
          }
        ]
      },
      objectclass: objectclassUUID,
      skip_relations: skipRelations,
      skip_metadata: skipMetadata,
      project_fields: projectFields
    }),
    [
      objectUUID,
      objectSID,
      objectclassUUID,
      projectFields,
      skipMetadata,
      skipRelations
    ]
  )

  const {
    yesObjects,
    isLoading,
    fetchYesObjects,
    pagination,
    isFetched,
    isFetching,
    error
  } = useFetchYesObjects({
    filterBody,
    showLoading,
    enabled: enabled && !!objectclassUUID && (!!objectUUID || !!objectSID),
    initialPagination: { limit: 1, offset: 0 }
  })

  useEffect(() => {
    if (isFetched && !yesObjects.length && displayMessageOnNoItemsFound) {
      displayUserError({
        error,
        userMessageFallback: t('error-message.object-not-found')
      })
    }
  }, [
    isFetched,
    yesObjects,
    displayMessageOnNoItemsFound,
    t,
    displayUserError,
    error
  ])

  return {
    yesObject: yesObjects[0] as YesObjectType | undefined,
    isLoading,
    isFetching,
    pagination,
    fetchObjects: fetchYesObjects
  }
}
