import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import apiAsync from 'infrastructure/shared/api/api-async'
import { HTTPClient } from 'infrastructure/shared/api'
import {
  AuthenticationType,
  PublicSessionToken
} from 'authentication/shared/types/authentication.type'
import { SitemodelUuid } from 'sitemodel/shared/types'
import { useSiteState } from 'siteState/shared'
import { SitemodelObjectState } from 'site/shared/hooks/useSitemodelFromUrl'
import { useLogout } from 'shared/hooks'
import { useEffect } from 'react'

const PrivateRoutes = () => {
  const [searchParams] = useSearchParams()
  const { sitemodelUuid } = useParams<SitemodelUuid>()

  const { token: tokenInState, logoutStatus } = useSelector(
    (state: AuthenticationType) => state.authentication
  )

  const [sitemodelObject] =
    useSiteState<SitemodelObjectState>('sitemodel-object')

  const isLoggingOut = logoutStatus === 'logging_out'

  if (isLoggingOut) {
    window.location.replace('/logged-out')
    return null
  }

  const { sitemodel, isLoading } = sitemodelObject || {}

  const tokenInURL = searchParams.get('token')

  const publicSessionToken: PublicSessionToken = window.publicSessionToken

  const token = tokenInURL ?? tokenInState ?? publicSessionToken

  const tokenType =
    !!token && token === publicSessionToken ? 'public' : 'private'

  const publicUrls: string[] = window.publicUrls

  const currentSitemodel = `/${sitemodelUuid ?? ''}`

  const isPublicUrl = publicUrls.includes(currentSitemodel)

  const isAuthenticating = !token || (!isPublicUrl && tokenType === 'public')

  if (isAuthenticating && !isLoading && sitemodel) {
    window.location.replace(
      `${window.authSitemodelUuid}?redirectOrigin=${encodeURIComponent(window.location.href)}`
    )
  }

  apiAsync.setAuthToken(token)
  HTTPClient.setToken(token)

  return <Outlet />
}

export default PrivateRoutes
