import { Fragment, useCallback, useEffect, useRef } from 'react'
import { Container, InnerContainer, SliderButton } from './Tabs.styles'
import { TabsProps } from './Tabs.types'

import { Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import TabItemComponent from './TabItem'
import { VerticalSeparator } from './TabItem.styles'
import { tabsComponentSizes } from './Tabs.enum'
import usePaginatedItems from './hooks/usePaginatedItems'

export default function Tabs({
  heightClass = 'regular',
  textColor = 'black',
  highlightColor,
  items,
  onItemSelect,
  disabled,
  focusable,
  showSeparators = true,
  contentContainerStyleMap,
  ...divProps
}: TabsProps) {
  const innerContainer = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const contentContainerStyles = useStyleMaps(contentContainerStyleMap)

  const {
    currentPageItems,
    goToNextPage,
    goToPreviousPage,
    displayArrows,
    isNextArrowDisabled,
    isPrevArrowDisabled,
    selectedItem,
    setSelectedItem
  } = usePaginatedItems({
    items,
    fontSize: tabsComponentSizes[heightClass].fontSize,
    containerRef: innerContainer,
    itemSpacing: tabsComponentSizes[heightClass].spaceBetween
  })

  const onItemSelected = useCallback(
    (index: number) => {
      setSelectedItem(currentPageItems[index])
      onItemSelect(currentPageItems[index]?.des)
    },
    [currentPageItems]
  )

  useEffect(() => {
    const item = items.find((item) => item.uuid === selectedItem?.uuid)
    if (item?.uuid) {
      onItemSelect(item?.des)
    }
  }, [currentPageItems, selectedItem])

  const previousLabel = `${selectedItem?.des} - ${t('navigation-slider.previous')}`
  const nextLabel = `${selectedItem?.des} - ${t('navigation-slider.next')}`

  return (
    <Container heightClass={heightClass} role='tablist' {...divProps}>
      {displayArrows && (
        <SliderButton
          heightClass={heightClass}
          onClick={goToPreviousPage}
          type='button'
          aria-label={previousLabel}
          disabled={isPrevArrowDisabled}
        >
          <Icon
            iconName='NavigationLeft'
            icSize='auto'
            icColor={isPrevArrowDisabled ? 'Gray_0_3' : 'blackText'}
          />
        </SliderButton>
      )}
      <InnerContainer
        style={contentContainerStyles}
        heightClass={heightClass}
        ref={innerContainer}
      >
        {currentPageItems.map((tab, tabIndex) => (
          <Fragment key={tab?.uuid}>
            {showSeparators && tabIndex > 0 && (
              <VerticalSeparator heightClass={heightClass} />
            )}
            <TabItemComponent
              key={tab?.uuid}
              tab={tab}
              tabIndex={tabIndex}
              selectedTabUuid={selectedItem?.uuid}
              highlightColor={highlightColor}
              heightClass={heightClass}
              textColor={textColor}
              onItemSelected={onItemSelected}
              disabled={disabled}
              focusable={focusable}
              showSeparators={showSeparators}
            />
          </Fragment>
        ))}
      </InnerContainer>

      {displayArrows && (
        <SliderButton
          onClick={goToNextPage}
          type='button'
          aria-label={nextLabel}
          heightClass={heightClass}
          disabled={isNextArrowDisabled}
          focusable={focusable}
        >
          <Icon
            iconName='NavigationRight'
            icSize='auto'
            icColor={isNextArrowDisabled ? 'Gray_0_3' : 'blackText'}
          />
        </SliderButton>
      )}
    </Container>
  )
}
