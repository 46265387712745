import { Document, Page, Text, View } from '@react-pdf/renderer'
import { DataTablesArea, FilterArea } from './components'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from '../site/Renderer/components/FilterFields/types'
import { Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { ColumnLayout } from 'site/Renderer/components/DataTables/types'
import ApplicationSystemTopLine from './components/ApplicationSystemTopLine'
import { TranslationContainer } from 'react-i18n-lite'
import locales from 'locales'
import { ConcatFields } from 'shared/utils/object/concatenateFields'

interface ListReportProps {
  filterFields?: FilterFieldsResponse
  filterComposition: string
  values: GroupedFieldValues
  title: string
  objects: YesObject[]
  columns: string[]
  columnsLayouts: ColumnLayout[]
  objectclass?: Objectclass
  filteractionName?: string
  orientation?: 'landscape' | 'portrait'
  concatFields?: ConcatFields
}

function ListReport({
  filterFields,
  filterComposition,
  values,
  title,
  objects,
  columns,
  columnsLayouts,
  objectclass,
  filteractionName,
  orientation = 'portrait',
  concatFields
}: ListReportProps) {
  return (
    <Document>
      <TranslationContainer locales={locales} defaultLanguage='pt-BR'>
        <Page size='A4' dpi={150} orientation={orientation}>
          <ApplicationSystemTopLine />
          {filterFields && (
            <FilterArea
              values={values}
              title={title}
              fields={filterFields}
              composition={filterComposition}
              filteractionName={filteractionName}
            />
          )}
          {objectclass && (
            <DataTablesArea
              objectclass={objectclass}
              objects={objects}
              columns={columns}
              columnsLayouts={columnsLayouts}
              concatFields={concatFields}
            />
          )}
        </Page>
      </TranslationContainer>
    </Document>
  )
}

export default ListReport
