import {
  DocumentListCard,
  Text,
  YesLoading
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { LoadingContainer } from './styles'

const DownloaderLoading = () => {
  const { t } = useTranslation()

  return (
    <LoadingContainer
      className='h-100 w-100 position-fixed d-flex align-items-center justify-content-center text-center p-4'
      style={{
        top: 0,
        left: 0,
        background: '#fff',
        zIndex: 9999,
        color: '#000'
      }}
    >
      <DocumentListCard
        className='d-flex flex-column m-auto align-items-center p-4 w-100'
        style={{ minWidth: 269, maxWidth: 358 }}
      >
        <YesLoading />
        <Text as='h2' variant='content-emphasis-06' className='mt-4 mb-4'>
          {t('report.wait-a-moment')}
          <br />
          {t('report.download-being-generated')}
        </Text>
        <Text as='div' variant='content-05'>
          <p className='m-0'>{t('report.can-take-some-minutes')}</p>
          <p className='m-0'>{t('report.please-wait')}</p>
        </Text>
      </DocumentListCard>
    </LoadingContainer>
  )
}

export default DownloaderLoading
