import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18n-lite'
import { MenuItemProps as MenuItem } from '../NavigationMenuBar'
import { Placement } from '../NavigationMenuBar.styles'

type VisibleHiddenItems = {
  visibleItems: MenuItem[]
  hiddenItems: MenuItem[]
}

type UseVisibleItemsProps = {
  items: MenuItem[]
  fontSize?: number
  itemSpacing?: number
  containerRef: RefObject<HTMLElement>
  placement?: Placement
}

// Average character width for Barlow font (in em units)
const BARLOW_AVG_CHAR_WIDTH = 0.5

function useVisibleItems({
  items,
  fontSize = 16,
  itemSpacing = 16,
  placement,
  containerRef
}: UseVisibleItemsProps) {
  const [visibleHiddenItems, setVisibleHiddenItems] =
    useState<VisibleHiddenItems>({
      visibleItems: [],
      hiddenItems: []
    })

  const { t } = useTranslation()

  const previousItems = useRef<MenuItem[]>([])

  const estimateItemWidth = useCallback(
    (item: MenuItem): number => {
      return (item?.label ?? '').length * BARLOW_AVG_CHAR_WIDTH * fontSize
    },
    [fontSize]
  )

  const calculateVisibleItems = useCallback(
    (
      containerWidth: number,
      itemSpacing: number,
      items: MenuItem[]
    ): VisibleHiddenItems => {
      const visibleItems: MenuItem[] = []
      const hiddenItems: MenuItem[] = []
      let totalWidth = 0
      const moreItemsLabel = t('navigation-menu.more-items')
      const moreItemsLabelWidth = estimateItemWidth({
        label: moreItemsLabel,
        items: []
      })
      const availableContainerWidth =
        containerWidth - (hiddenItems?.length > 0 ? moreItemsLabelWidth : 0)

      const itemsIndexes = items.reduce(
        (acc: Record<string, number>, { label }, index) => {
          acc[label] = index
          return acc
        },
        {}
      )

      for (let i = 0; i < items.length; i++) {
        const itemWidth = estimateItemWidth(items[i])
        const itemTotalWidth = itemWidth + itemSpacing + (i % 2 === 1 ? 2 : 0)
        const lastVisibleItemIndex =
          itemsIndexes[visibleItems[visibleItems.length - 1]?.label] ?? -1

        if (totalWidth + itemTotalWidth <= availableContainerWidth) {
          if (
            lastVisibleItemIndex < 0 ||
            (lastVisibleItemIndex >= 0 &&
              Math.abs(itemsIndexes[items[i].label] - lastVisibleItemIndex) <=
                1)
          ) {
            visibleItems.push(items[i])
            totalWidth += itemTotalWidth
          }
        } else {
          hiddenItems.push(items[i])
        }
      }

      return { visibleItems, hiddenItems }
    },
    [estimateItemWidth, t]
  )

  const updateVisibleItems = useCallback(() => {
    if (containerRef.current) {
      const rightNavigationWidth =
        (document.querySelector('#navigation-right') as HTMLDivElement)
          ?.offsetWidth ?? 0

      const containerWidth =
        containerRef.current.offsetWidth - rightNavigationWidth
      const result = calculateVisibleItems(containerWidth, itemSpacing, items)
      setVisibleHiddenItems(result)
    }
  }, [calculateVisibleItems, containerRef, itemSpacing, items])

  useEffect(() => {
    if (placement !== 'left') return

    const resizeObserver = new ResizeObserver(updateVisibleItems)

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [placement, containerRef, updateVisibleItems])

  useEffect(() => {
    if (placement !== 'left') return

    if (JSON.stringify(items) !== JSON.stringify(previousItems.current)) {
      updateVisibleItems()
      previousItems.current = items
    }
  }, [items, placement, updateVisibleItems])

  const menuVisibleItems = useMemo(() => {
    const moreItemsMenu = {
      label: t('navigation-menu.more-items'),
      items: visibleHiddenItems.hiddenItems
    }

    if (visibleHiddenItems.hiddenItems.length > 0) {
      return [...visibleHiddenItems.visibleItems, moreItemsMenu]
    }

    return visibleHiddenItems.visibleItems
  }, [t, placement, visibleHiddenItems])

  return placement === 'left' ? menuVisibleItems : items
}

export default useVisibleItems
