import {
  DocumentListCard,
  feedbackcard,
  Text,
  YesLoading
} from '@yes.technology/react-toolkit'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'
import downloadUrl from 'shared/utils/url/downloadUrl'
import DownloaderLoading from '../DownloaderLoading'

type DocumentDownloaderProps = {
  url: string | null
  filename: string
  loading?: boolean
}

export default function DocumentDownloader({
  url,
  filename,
  loading
}: DocumentDownloaderProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (url && !loading) {
      downloadUrl({ url, filename })

      feedbackcard(t('report.download-finished-title'), {
        message: t('report.download-finished-message'),
        type: 'success'
      })
    }
  }, [url, t, filename, loading])

  return loading ? <DownloaderLoading /> : null
}
