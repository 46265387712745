import styled, { css } from 'styled-components'

export const Container = styled.div<{ isDesktop: boolean }>`
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      width: 180px;
      background-color: #fff;
      position: absolute;
      top: 52px;
      right: -8px;
      padding: 8px 13px 0;
      border-radius: 6px;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
      z-index: 10000;

      &:before {
        content: '';
        position: absolute;
        top: -11px;
        right: 12px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid #fff;
      }
    `}
`

export const Label = styled.button<{ isDesktop: boolean }>`
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  padding: 0;

  > figure > svg > path {
    fill: #788b9e;
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      cursor: default !important;

      > span {
        color: #a4a4a4;
      }
    `}
`

export const LanguageItems = styled.div<{ isDesktop: boolean }>`
  padding: 8px 0;
  display: grid;
  row-gap: 8px;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      padding: 16px 0;
      row-gap: 16px;
    `}
`

export const LanguageItem = styled.a<{ isCurrentLanguage: boolean }>`
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: #9e9e9e;
  text-decoration: none;

  > span {
    color: #9e9e9e;

    ${({ isCurrentLanguage }) =>
      isCurrentLanguage &&
      css`
        font-weight: 600;
        color: #303032;
      `}
  }
`

export const Image = styled.img`
  width: 16px;
  height: 16px;
  border: solid 1px #acacac;
  border-radius: 16px;
`
