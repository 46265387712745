import { Field, Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { chunk } from 'lodash'
import { useCallback, useMemo } from 'react'
import CardsReport from 'report/CardsReport'
import ListReport from 'report/ListReport'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { FieldSettings } from 'shared/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import useTableLayouts from 'site/Renderer/components/DataTables/hooks/useTableLayouts'
import { GroupedFieldValues } from 'site/Renderer/components/FilterFields/types'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'
import { RelationsTableLayouts } from '../useRelationsTableLayouts/useRelationsTableLayouts'

type UseSplitReportComponentsProps = {
  splitThreshold: number
  fields: Field[]
  relationFields: Field[]
  filterFields?: FilterFieldsResponse
  filterComposition: string
  fieldValues: GroupedFieldValues
  yesObjects: YesObject[]
  reportType: 'list' | 'card'
  layoutClassificationUuid?: string
  objectclass?: Objectclass
  relationsTableLayouts?: RelationsTableLayouts
  isLoading: boolean
  filteractionName?: string
  concatFields?: ConcatFields
  relationConfig?: RelationConfigProps
  fieldSettings?: FieldSettings
}

export default function useSplitReportComponents({
  splitThreshold,
  fields,
  relationFields,
  filterFields,
  filterComposition,
  fieldValues,
  yesObjects,
  reportType,
  layoutClassificationUuid,
  objectclass,
  relationsTableLayouts,
  isLoading,
  filteractionName,
  concatFields,
  relationConfig,
  fieldSettings
}: UseSplitReportComponentsProps): JSX.Element[] {
  const columns = useMemo(
    () => fields.map((field) => field.column_json),
    [fields]
  )

  const columnsLayoutsResponse = useTableLayouts({ layoutClassificationUuid })
  const columnsLayouts = columnsLayoutsResponse?.[0]?.columnsLayouts

  const columnsToRemove = useMemo(
    () => new Set(columnsLayoutsResponse?.[0]?.removeColumns ?? []),
    [columnsLayoutsResponse]
  )

  const filteredColumns = useMemo(
    () => columns.filter((column) => !columnsToRemove.has(column)),
    [columns, columnsToRemove]
  )

  const title =
    objectclass?.des || filterFields?.objectclass?.des || 'Filtering Action'

  const renderListReport = useCallback(
    (objects: YesObject[]) => (
      <ListReport
        key={objects[0].uuid}
        values={fieldValues}
        title={title}
        filterComposition={filterComposition}
        filterFields={filterFields}
        objects={objects}
        columns={filteredColumns}
        columnsLayouts={columnsLayouts}
        objectclass={objectclass || filterFields?.objectclass}
        filteractionName={filteractionName}
        concatFields={concatFields}
      />
    ),
    [
      columnsLayouts,
      fieldValues,
      filterComposition,
      filterFields,
      filteractionName,
      filteredColumns,
      objectclass,
      title,
      concatFields
    ]
  )

  const renderCardsReport = useCallback(
    (objects: YesObject[]) => (
      <CardsReport
        key={objects[0].uuid}
        fields={filterFields}
        objectFields={fields}
        relationClasses={relationFields}
        values={fieldValues}
        title={title}
        objectclass={objectclass || filterFields?.objectclass}
        objects={objects}
        filterComposition={filterComposition}
        filteractionName={filteractionName}
        concatFields={concatFields}
        relationConfig={relationConfig}
        fieldSettings={fieldSettings}
        relationsTableLayouts={relationsTableLayouts}
      />
    ),
    [
      filterFields,
      fields,
      relationFields,
      fieldValues,
      title,
      objectclass,
      filterComposition,
      filteractionName,
      concatFields,
      relationConfig,
      fieldSettings,
      relationsTableLayouts
    ]
  )

  return useMemo(() => {
    if (isLoading) return []
    return chunk(yesObjects, reportType === 'list' ? splitThreshold : 1).map(
      (objects) =>
        reportType === 'list'
          ? renderListReport(objects)
          : renderCardsReport(objects)
    )
  }, [
    yesObjects,
    splitThreshold,
    reportType,
    renderListReport,
    renderCardsReport,
    isLoading
  ])
}
