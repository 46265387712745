import { Icon, Text } from '@yes.technology/react-toolkit'
import { useEffect, useId, useRef } from 'react'
import { useIsOpen } from 'shared/hooks'
import styled from 'styled-components'
import { MenuItemProps } from '../NavigationMenuBar/NavigationMenuBar'

type MenuItemType = {
  label: string
  items: MenuItemProps[]
}

export const MenuItemContainer = styled.div`
  border-bottom: 2px solid #f7f8fa;
  padding: 12px 0;

  @media (min-width: 768px) {
    padding: 8px 16px;
    border-bottom: 0;
  }
`

export const MenuItemLabel = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  > figure > svg > path {
    fill: #788b9e;
  }

  @media (min-width: 768px) {
    > span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`

export const MenuSubItemContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px 12px;

  @media (min-width: 768px) {
    gap: 10px;
    padding: 8px;
  }
`

export const MenuSubItem = styled.a`
  text-decoration: none;
  color: #303032;
`

const MenuItem = ({ label, items }: MenuItemType) => {
  const { isOpen, open, close } = useIsOpen(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const subItemsRef = useRef<(HTMLAnchorElement | null)[]>([])
  const menuId = useId()

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        isOpen ? close() : open()
        break
      case 'ArrowDown':
        if (isOpen && subItemsRef.current.length > 0) {
          event.preventDefault()
          subItemsRef.current[0]?.focus()
        }
        break
      case 'Escape':
        if (isOpen) {
          event.preventDefault()
          close()
          menuRef.current?.focus()
        }
        break
    }
  }

  const handleSubItemKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        if (index === 0) {
          menuRef.current?.focus()
        } else {
          subItemsRef.current[index - 1]?.focus()
        }
        break
      case 'ArrowDown':
        event.preventDefault()
        if (index < subItemsRef.current.length - 1) {
          subItemsRef.current[index + 1]?.focus()
        }
        break
      case 'Escape':
        event.preventDefault()
        close()
        menuRef.current?.focus()
        break
    }
  }

  useEffect(() => {
    subItemsRef.current = subItemsRef.current.slice(0, items.length)
  }, [items])

  return (
    <MenuItemContainer>
      <MenuItemLabel
        ref={menuRef}
        onClick={isOpen ? close : open}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role='button'
        aria-expanded={isOpen}
        aria-controls={`sub-menu-${menuId}`}
      >
        <Text variant={isOpen ? 'content-emphasis-03' : 'content-03'}>
          {label}
        </Text>
        <Icon iconName={isOpen ? 'DropdownArrowUpNew' : 'DropdownArrowDown'} />
      </MenuItemLabel>
      {isOpen && (
        <MenuSubItemContainer id={`sub-menu-${menuId}`} role='menu'>
          {items.map((item, itemIndex) => (
            <MenuSubItem
              key={`menu-item-${itemIndex}`}
              href={item.href}
              ref={(el) => (subItemsRef.current[itemIndex] = el)}
              onKeyDown={(e) => handleSubItemKeyDown(e, itemIndex)}
              role='menuitem'
            >
              <Text variant='content-03'>{item.label}</Text>
            </MenuSubItem>
          ))}
        </MenuSubItemContainer>
      )}
    </MenuItemContainer>
  )
}

export default MenuItem
