import { ReactNode } from 'react'

import { useTranslation } from 'react-i18n-lite'

import { useIsOpen } from 'shared/hooks'

import { LanguageType } from 'site/shared/types/toparea.type'

import LanguageMenu from './LanguageMenu'

import { Button, Container } from './DesktopLanguageContainer.styles'

import { getLanguageImageSrc, Lang } from './utils'

type DesktopLanguageContainerProps = {
  children: ReactNode
  availableLanguages?: LanguageType[]
}

const DesktopLanguageContainer = ({
  children,
  availableLanguages
}: DesktopLanguageContainerProps) => {
  const { isOpen, open, close } = useIsOpen(false)

  const { language: currentLang } = useTranslation()

  return (
    <Container>
      <div>{children}</div>
      <div>|</div>
      <Button onClick={isOpen ? close : open}>
        <img src={getLanguageImageSrc(currentLang as Lang)} />
      </Button>
      {isOpen && <LanguageMenu languages={availableLanguages} isDesktop />}
    </Container>
  )
}

export default DesktopLanguageContainer
