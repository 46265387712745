import { useMemo } from 'react'
import { LoadComponent } from 'site/Renderer'
import { GenericObjectCardProps } from '../GenericObjectCard'
import { GenericObjectCardContentWrapperProps } from '../GenericObjectCardContentWrapper'

export type UseGenericObjectCardWrapperProps = {
  wrapperType: GenericObjectCardProps['wrapperType']
  wrapperProps: GenericObjectCardContentWrapperProps['wrapperProps']
  components: GenericObjectCardContentWrapperProps['components']
  children: React.ReactNode
}

const useGenericObjectCardWrapper = ({
  wrapperType,
  wrapperProps,
  children,
  components
}: UseGenericObjectCardWrapperProps) => {
  const componentWrapperProps = useMemo(() => {
    if (wrapperType === 'Accordion') {
      return {
        defaultOpenedIndexes: [0],
        heightClass: 'small',
        ...wrapperProps,
        items: [
          {
            uuid: '1',
            des: '',
            complement: '1'
          }
        ],
        renderAccordionDescription: () =>
          !!components && components?.length > 0 ? (
            <LoadComponent components={components} />
          ) : null,
        renderAccordionTitle: () => children,
        allowInteractionOnlyOnIconClick: true
      }
    }
    return {}
  }, [wrapperProps, wrapperType, children, components])

  return {
    props: componentWrapperProps
  }
}

export default useGenericObjectCardWrapper
