import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { PDFInformationalInput } from '@yes.technology/react-toolkit'
import getStyles from './styles'
import FilterGroupChip from '../FilterGroupChip/FilterGroupChip'

interface FilterHeadProps {
  isSingleGroup: boolean
  filteractionName?: string
  composition: string
}

const MATCHING_RGX = /([0-9]+)/g

function FilterHead({
  isSingleGroup,
  filteractionName,
  composition
}: FilterHeadProps) {
  const styles = getStyles()

  const texts = composition
    .split(MATCHING_RGX)
    .map((subgroup) => subgroup.trim())

  const alternatingTextLabel = () =>
    texts.map((text, index) =>
      index % 2 === 0 ? (
        <Text style={styles.spacingFilterGroup} key={text + index}>
          {text}
        </Text>
      ) : (
        <FilterGroupChip
          key={text + index}
          text={`Grupo de Filtros ${text}`}
          style={styles.spacingFilterGroup}
        />
      )
    )

  return (
    <View style={styles.mainContainer}>
      {filteractionName && (
        <View style={styles.firstItem}>
          <PDFInformationalInput
            heightClass='doubleXSmall'
            label='Ação de Filtragem'
            value={filteractionName}
          />
        </View>
      )}

      <View style={styles.midItem}>
        <View style={styles.compositionField}>
          <Text style={styles.compositionLabel}>
            Composição da Ação de Filtragem
          </Text>
          <View style={styles.compositionGroupContent}>
            {texts.length === 1 ? (
              <FilterGroupChip
                text={`Grupo de Filtros 1`}
                style={styles.spacingFilterGroup}
              />
            ) : (
              alternatingTextLabel()
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default FilterHead
