import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 1rem;
  border-bottom: 1px solid #c9c9cb;

  nav {
    padding: 0;
  }
`

export const IconButton = styled.button`
  background: transparent;
  border: none;

  &:focus {
    box-shadow: unset !important;
  }

  > figure:focus {
    outline: unset !important;
  }

  &:hover {
    opacity: 0.8;
  }

  * {
    color: #788b9e;
  }
`

export const Menu = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`
