import { Text, View } from '@react-pdf/renderer'
import { Objectclass, PDFCard, YesObject } from '@yes.technology/react-toolkit'
import {
  concatenateDisplayText,
  concatenateFields,
  ConcatFields
} from 'shared/utils/object/concatenateFields'
import { CellValue } from 'site/Renderer/components/DataTables/types'
import PDFTableCell from './PDFTableCell'
import styles from './styles'

export type ColumnLayout = {
  width: string
  text_wrapping: boolean
  freeze: boolean
  field_names: string[]
  name: string
}

type TableItem = {
  uuid: string
  [columnName: string]: CellValue
}

interface PDFDataTableProps {
  columns: string[]
  fields: Objectclass['fields']
  objects: TableItem[]
  columnsLayouts: ColumnLayout[]
  itemsPerPage: number
  concatFields?: ConcatFields
  title?: string
}

const PDFDataTable = ({
  columns,
  fields,
  objects,
  columnsLayouts,
  itemsPerPage,
  concatFields,
  title
}: PDFDataTableProps) => {
  const layoutColumnsNames = columnsLayouts.map(
    (column) => column.field_names[0]
  )

  const columnsPerSection = 6
  const sectionsCount = Math.ceil(layoutColumnsNames.length / columnsPerSection)

  const sections = Array.from({ length: sectionsCount }, (_, index) => {
    const sectionStartIndex = index * columnsPerSection
    const sectionEndIndex = sectionStartIndex + columnsPerSection

    return layoutColumnsNames.slice(sectionStartIndex, sectionEndIndex)
  })

  const totalPages = Math.ceil(objects.length / itemsPerPage)
  const paginatedObjects = Array.from({ length: totalPages }, (_, index) =>
    objects.slice(index * itemsPerPage, (index + 1) * itemsPerPage)
  )

  return (
    <View style={styles.container}>
      {paginatedObjects.map((pageObjects) =>
        sections.map((sectionColumns, index) => (
          <PDFCard
            key={`section-${index + 1}`}
            style={styles.section}
            noGutters
          >
            {title && (
              <View style={styles.areaLine}>
                <Text style={styles.areaLineTitle}>{title}</Text>
              </View>
            )}

            <View style={styles.table}>
              <View fixed wrap={false} style={styles.tableRow}>
                {sectionColumns.map((column) => {
                  const columnDisplayText = concatenateDisplayText({
                    concatFields,
                    fields,
                    columnName: column
                  })

                  return (
                    <Text key={column} style={styles.tableHeadCell}>
                      {columnDisplayText}
                    </Text>
                  )
                })}
              </View>

              {pageObjects.map((currentObject, index) => {
                const yesObject = currentObject as YesObject
                const concatenatedFields = concatFields
                  ? concatenateFields(concatFields, yesObject)
                  : yesObject
                return (
                  <View
                    key={concatenatedFields.uuid}
                    style={[
                      styles.tableRow,
                      index % 2 !== 0 ? styles.evenRow : {}
                    ]}
                  >
                    {sectionColumns.map((column) => {
                      return (
                        <PDFTableCell
                          key={column}
                          value={concatenatedFields[column]}
                          type={fields[column].type}
                        />
                      )
                    })}
                  </View>
                )
              })}
            </View>
          </PDFCard>
        ))
      )}
    </View>
  )
}

export default PDFDataTable
