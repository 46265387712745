import { useQuery } from '@tanstack/react-query'
import { Field, YesObject } from '@yes.technology/react-toolkit'
import getRelationCounterpartField from 'site/Renderer/components/ObjectView/lib/getRelationCountertpartField/getRelationCounterpartField'
import fetchYesObjects from '../api/fetchYesObjects'

export async function populateObjectRelations(
  objects: YesObject[],
  relationFields: Field[]
): Promise<YesObject[]> {
  return Promise.all(
    objects.map(async (object) => {
      const updated = { ...object }

      await Promise.all(
        relationFields.map(async (relationField) => {
          if (!object?.[relationField.column_json]) return

          const relationCounterpartField = getRelationCounterpartField({
            relationFieldRelation: relationField.relation,
            fieldValues: object
          })

          const [relationCounterpartColumn] = Object.keys(
            relationCounterpartField || {}
          )

          const response = await fetchYesObjects({
            filterBody: {
              query: {
                $and: [
                  {
                    [`${relationCounterpartColumn}.uuid`]: {
                      $in: [`${object?.uuid}`]
                    },
                    'situation.des': {
                      $eq: '40'
                    }
                  }
                ]
              },
              objectclass: relationField.relation?.uuid_objectclass || '',
              orderby: [],
              pagination: {}
            }
          })

          if (response?.objects?.length) {
            updated[relationField.column_json] = response.objects
          }
        })
      )

      return updated
    })
  )
}

export default function usePopulateObjectRelations(
  objects: YesObject[],
  relationFields: Field[]
) {
  const { data, error, isLoading } = useQuery<YesObject[], Error>({
    queryKey: ['all-objects', objects.map((object) => object.uuid)],
    queryFn: () => populateObjectRelations(objects, relationFields),
    enabled: !!objects.length
  })

  return {
    data: data ?? [],
    error,
    isLoading
  }
}
