import { Classification, YesObject } from '@yes.technology/react-toolkit'
import ContactObjectCard from 'component/ContactObjectCard'
import GenericObjectCard from 'component/GenericObjectCard'
import TransportOrderCard from 'component/TransportOrderCard'
import TaskObjectCard from 'component/TaskObjectCard'
import React from 'react'
import splitIntoChunks from 'shared/utils/array/splitIntoChunks'
import { CardModel, ColumnProps } from '../../Kanban.types'
import useMoveTaskCardAnimation from '../../hooks/useMoveTaskCardAnimation'
import {
  CardContainer,
  ColumnCard,
  ColumnContainer,
  ColumnsContainer,
  KanbanColumnTitle
} from './KanbanAutoColumns.styles'
import { KanbanContext } from './useKanbanContext'

function getCardComponentFromModel<T extends YesObject = YesObject>(
  cardModel: CardModel
) {
  const validCardModels = {
    GenericObjectCard,
    TaskObjectCard,
    ContactObjectCard,
    TransportOrderCard
  }
  return validCardModels[cardModel] as React.FC<T>
}

type KanbanAutoColumnsProps = {
  relationObjects: YesObject[]
  groupedYesObjects: Record<string, YesObject[]>
  cardModel: CardModel
  currentSection: number
  columnsPerPage: number
  sections: Record<string, YesObject[]>
  columnsOverrideAttributes: Record<string, ColumnProps>
  statusActionClassification?: Classification[]
  onMoveTask: (
    taskUuid: string,
    newStatusColumn: string,
    setIsFadeInAnimationStarted: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
}

function KanbanAutoColumns({
  relationObjects,
  groupedYesObjects,
  cardModel,
  currentSection,
  columnsPerPage,
  sections,
  columnsOverrideAttributes,
  statusActionClassification,
  onMoveTask
}: KanbanAutoColumnsProps) {
  const CardComponent = getCardComponentFromModel(cardModel)

  const splittedColumns = splitIntoChunks(Object.keys(sections), columnsPerPage)

  const currentSectionColumns = splittedColumns[currentSection] ?? []

  const {
    targetCardUuid,
    isFadeOutAnimationStarted,
    isFadeInAnimationStarted,
    onStartTaskMove
  } = useMoveTaskCardAnimation({
    onMoveTask
  })

  return (
    <KanbanContext.Provider
      value={{ onStatusChange: onStartTaskMove, statusActionClassification }}
    >
      <ColumnsContainer>
        {currentSectionColumns.map((column) => {
          const currentColumnObjects = groupedYesObjects[column] || []

          const columnRelationObject = relationObjects.find(
            (relationObject) => relationObject.code === column
          )

          const columnLabel =
            columnsOverrideAttributes?.[column]?.label ||
            columnRelationObject?.des

          return (
            <ColumnContainer
              key={column}
              $basis={Math.round(100 / columnsPerPage)}
            >
              <ColumnCard fullHeight>
                <KanbanColumnTitle>{columnLabel}</KanbanColumnTitle>
                {currentColumnObjects.map((object) => (
                  <CardContainer
                    key={object.uuid}
                    $isFadingOut={
                      isFadeOutAnimationStarted &&
                      object.uuid === targetCardUuid
                    }
                    $isFadingIn={
                      isFadeInAnimationStarted && object.uuid === targetCardUuid
                    }
                  >
                    <CardComponent {...object} />
                  </CardContainer>
                ))}
              </ColumnCard>
            </ColumnContainer>
          )
        })}
      </ColumnsContainer>
    </KanbanContext.Provider>
  )
}

export default KanbanAutoColumns
