import React, { useMemo } from 'react'
import { pdf } from '@react-pdf/renderer'
import { useQuery } from '@tanstack/react-query'
import utilsMergePdfs from 'report/utils/mergePdfs'

const emptyBlobsArray: Readonly<Blob[]> = []

const getReactPDFBuffers = (
  components: React.JSX.Element[]
): Promise<ArrayBuffer>[] =>
  components.map(async (component) =>
    (await pdf(component).toBlob()).arrayBuffer()
  )

export type UseReportPdfsProps = {
  components: React.JSX.Element[]
  sourceObjects: object[]
  shouldMerge: boolean
  getPdfBuffers?: typeof getReactPDFBuffers
  mergePdfs?: typeof utilsMergePdfs
  isLoading: boolean
}

export default function useReportPdfs({
  components,
  sourceObjects,
  shouldMerge,
  getPdfBuffers = getReactPDFBuffers,
  mergePdfs = utilsMergePdfs,
  isLoading
}: UseReportPdfsProps) {
  const { data: blobs = emptyBlobsArray, isLoading: isRendering } = useQuery({
    queryKey: [
      'useReportPdfs',
      shouldMerge ? 'merge' : 'split',
      ...sourceObjects
    ],
    queryFn: () =>
      Promise.all(getPdfBuffers(components)).then(async (pdfBuffers) => {
        const finishedBuffers = shouldMerge
          ? [await mergePdfs(pdfBuffers)]
          : pdfBuffers

        return finishedBuffers.map(
          (finishedBuffer) =>
            new Blob([finishedBuffer], {
              type: 'application/pdf'
            })
        )
      }),
    enabled: !isLoading
  })

  const urls = useMemo(
    () => blobs.map((blob) => URL.createObjectURL(blob)),
    [blobs]
  )

  return {
    blobs,
    urls,
    isRendering
  }
}
