import { Text, View } from '@react-pdf/renderer'
import getStyles from './styles'

interface PDFAreaLineProps {
  title: string
  backgroundColor?: string
  color?: string
  height?: number
  fontSize?: number
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
  fontWeight?: 'normal' | 600
}

const PDFAreaLine = ({
  title,
  backgroundColor = '#006BFF',
  height = 24,
  color = '#ffffff',
  fontSize = 10,
  justifyContent,
  fontWeight
}: PDFAreaLineProps) => {
  const styles = getStyles(
    backgroundColor,
    height,
    color,
    fontSize,
    justifyContent,
    fontWeight
  )
  return (
    <View style={styles.header}>
      <Text style={styles.title}>{title}</Text>
    </View>
  )
}

export default PDFAreaLine
