import { FilterQuery } from '@yes.technology/react-toolkit'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { FieldSettings } from 'shared/types'
import { GroupedFieldValues } from 'site/Renderer/components/FilterFields/types'
import { RelationConfigProps } from 'site/Renderer/components/ObjectView/components/types/object-view'
import useObjectFields from 'site/Renderer/components/ObjectView/hooks/useObjectFields'
import { useSiteState } from 'siteState/shared'
import {
  useFetchYesObjects,
  usePopulateObjectRelations
} from 'yesObject/shared'
import useRelationsTableLayouts from '../useRelationsTableLayouts/useRelationsTableLayouts'

type UseReportDataProps = {
  filteractionUuid?: string
  enabled?: boolean
  relationConfig?: RelationConfigProps
  fieldSettings?: FieldSettings
  classificationUuid?: string
}

export default function useReportData({
  filteractionUuid,
  enabled,
  relationConfig,
  fieldSettings,
  classificationUuid
}: UseReportDataProps) {
  const [fieldValues] = useSiteState<GroupedFieldValues>('field-values')
  const [filterFields] = useSiteState<FilterFieldsResponse>('filter-fields')
  const [filterQuery] = useSiteState<FilterQuery>('filter-query')
  const [filterComposition] = useSiteState<string>('field-composition')
  const [objectclassUUID] = useSiteState<string>('objectclassuuid')
  const [filteractionName] = useSiteState<string>('filteraction-name')

  const { yesObjects, isLoading: isLoadingYesObjects } = useFetchYesObjects({
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUUID
    },
    filteractionUuid,
    initialPagination: {},
    enabled
  })

  const objectFields = useObjectFields({
    objectclassUuid: objectclassUUID,
    classificationUuid,
    fieldSettings
  })

  const { relationsTableLayouts, isFetching: isFetchingRelationsLayouts } =
    useRelationsTableLayouts({
      relationConfig
    })

  const { data } = usePopulateObjectRelations(
    yesObjects,
    objectFields.relationFields
  )

  return {
    yesObjects: data,
    requestLoading: isLoadingYesObjects || isFetchingRelationsLayouts,
    objectFields,
    fieldValues,
    filterFields,
    filterComposition,
    filteractionName,
    relationsTableLayouts
  }
}
