import { useIsMobile } from '@yes.technology/react-toolkit'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { TabItem } from '../Tabs.types'

type PaginatedItems = {
  pages: TabItem[][]
  totalPages: number
}

type UsePaginatedItemsProps = {
  items: TabItem[]
  fontSize: number
  itemSpacing: number
  containerRef: RefObject<HTMLElement>
}

// Average character width for Barlow font (in em units)
const BARLOW_AVG_CHAR_WIDTH = 0.5

function usePaginatedItems({
  items,
  fontSize,
  itemSpacing,
  containerRef
}: UsePaginatedItemsProps) {
  const [selectedItem, setSelectedItem] = useState<TabItem | null>(
    items?.[0] ?? null
  )

  const [paginatedItems, setPaginatedItems] = useState<PaginatedItems>({
    pages: [],
    totalPages: 0
  })

  const prevWidth = useRef(0)

  const [currentPage, setCurrentPage] = useState(0)

  const isMobile = useIsMobile()

  const estimateItemWidth = useCallback(
    (item: TabItem): number => {
      return (item?.des ?? '').length * BARLOW_AVG_CHAR_WIDTH * fontSize
    },
    [fontSize]
  )

  const paginateItemsFit = useCallback(
    (
      containerWidth: number,
      itemSpacing: number,
      items: TabItem[]
    ): PaginatedItems => {
      const pages: TabItem[][] = []
      let currentPage: TabItem[] = []
      let totalWidth = 0

      for (let i = 0; i < items.length; i++) {
        const itemWidth = estimateItemWidth(items[i])
        const itemTotalWidth = itemWidth + itemSpacing + (i % 2 === 1 ? 2 : 0)

        if (totalWidth + itemTotalWidth <= containerWidth) {
          currentPage.push(items[i])
          totalWidth += itemTotalWidth
        } else {
          if (currentPage.length > 0) {
            pages.push(currentPage)
          }
          currentPage = [items[i]]
          totalWidth = itemTotalWidth
        }
      }

      if (currentPage.length > 0) {
        pages.push(currentPage)
      }

      return {
        pages,
        totalPages: pages.length
      }
    },
    [estimateItemWidth]
  )

  useEffect(() => {
    const updatePagination = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.offsetWidth
        if (newWidth !== prevWidth.current) {
          prevWidth.current = newWidth
          const containerWidth = containerRef.current.offsetWidth
          const result = paginateItemsFit(containerWidth, itemSpacing, items)
          setPaginatedItems(result)

          const currentSelectedItemPageIndex = result.pages.findIndex((page) =>
            page.find((item) => item.uuid === selectedItem?.uuid)
          )

          if (currentSelectedItemPageIndex !== -1) {
            setCurrentPage(
              currentSelectedItemPageIndex !== -1
                ? currentSelectedItemPageIndex
                : 0
            )
          }
        }
      }
    }

    updatePagination()

    const resizeObserver = new ResizeObserver(updatePagination)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [items, itemSpacing, paginateItemsFit, containerRef, selectedItem])

  const goToNextPage = useCallback(() => {
    const { totalPages, pages } = paginatedItems
    setCurrentPage((prevPage) => {
      const page = prevPage < totalPages - 1 ? prevPage + 1 : prevPage

      const items = pages[page]

      if (isMobile && items?.[0]?.uuid) {
        setSelectedItem(items[0])
      }

      return page
    })
  }, [paginatedItems, isMobile])

  const goToPreviousPage = useCallback(() => {
    const { pages } = paginatedItems
    setCurrentPage((prevPage) => {
      const page = prevPage > 0 ? prevPage - 1 : prevPage

      const items = pages[page]

      if (isMobile && items?.[0]?.uuid) {
        setSelectedItem(items[0])
      }

      return page
    })
  }, [paginatedItems.pages, isMobile])

  const displayArrows = paginatedItems.totalPages > 1

  const isPrevArrowDisabled = currentPage === 0
  const isNextArrowDisabled = currentPage === paginatedItems.totalPages - 1

  return {
    currentPageItems: paginatedItems.pages[currentPage] || [],
    totalPages: paginatedItems.totalPages,
    goToNextPage,
    goToPreviousPage,
    displayArrows,
    isPrevArrowDisabled,
    isNextArrowDisabled,
    setSelectedItem,
    selectedItem
  }
}

export default usePaginatedItems
