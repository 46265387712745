import DOMPurify from 'dompurify'
import { useEffect } from 'react'

export function useSanitizeHtml(): (html: string) => string {
  useEffect(() => {
    DOMPurify.addHook('beforeSanitizeAttributes', (node: Element) => {
      if (node.tagName === 'A' && node.hasAttribute('target')) {
        node.setAttribute('data-target', node.getAttribute('target') || '')
      }
    })

    DOMPurify.addHook('afterSanitizeAttributes', (node: Element) => {
      if (node.tagName === 'A') {
        if (node.hasAttribute('data-target')) {
          node.setAttribute('target', node.getAttribute('data-target') || '')
          node.removeAttribute('data-target')
        }
        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener noreferrer')
        }
      }
    })

    return () => {
      DOMPurify.removeAllHooks()
    }
  }, [])

  return (html: string) =>
    DOMPurify.sanitize(html, {
      ADD_ATTR: ['target'],
      ALLOW_DATA_ATTR: true
    })
}
