import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18n-lite'

import { GenericError } from '@yes.technology/react-toolkit'

import { MainContainer } from './style'

function GenericErrorPage() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <>
      <MainContainer>
        <GenericError
          des={t('error-message.generic-error')}
          complement={t('error-message.generic-error-complement')}
          btnLabel={t('error-message.generic-error-label')}
          onClick={() => navigate(-1)}
        />
      </MainContainer>
    </>
  )
}

export default GenericErrorPage
