import { View } from '@react-pdf/renderer'
import { Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { ColumnLayout } from 'site/Renderer/components/DataTables/types'
import PDFAreaLine from '../PDFAreaLine'
import PDFDataTable from '../PDFDataTable/PDFDataTable'

interface DataTablesAreaProps {
  objectclass: Objectclass
  objects: YesObject[]
  columns: string[]
  columnsLayouts: ColumnLayout[]
  concatFields?: ConcatFields
}

const DEFAULT_ITEMS_PER_PAGE = 10

function DataTablesArea({
  objectclass,
  objects,
  columns,
  columnsLayouts,
  concatFields
}: DataTablesAreaProps) {
  return (
    <View>
      <PDFAreaLine title={objectclass.des} />

      <PDFDataTable
        objects={objects}
        fields={objectclass.fields}
        columns={columns}
        columnsLayouts={columnsLayouts}
        itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
        concatFields={concatFields}
      />
    </View>
  )
}

export default DataTablesArea
