import { useTranslation } from 'react-i18n-lite'

import { Text, Icon } from '@yes.technology/react-toolkit'

import { useIsOpen } from 'shared/hooks'

import { LanguageType } from 'site/shared/types/toparea.type'

import { getLanguageImageSrc, Lang } from './utils'

import {
  Container,
  Label,
  LanguageItems,
  LanguageItem,
  Image
} from './LanguageMenu.styles'

type LanguageMenuProps = {
  languages?: LanguageType[]
  isDesktop?: boolean
}

const LanguageMenu = ({ languages, isDesktop = false }: LanguageMenuProps) => {
  const { isOpen, open, close } = useIsOpen(isDesktop)

  const { t, language: currentLang } = useTranslation()

  const handleClick = () => {
    if (isDesktop) return

    isOpen ? close() : open()
  }

  if (!languages) return null

  return (
    <Container isDesktop={isDesktop}>
      <Label isDesktop={isDesktop} onClick={handleClick}>
        <Text variant={isOpen ? 'content-emphasis-03' : 'content-03'}>
          {t('topline.selectLanguage')}
        </Text>
        {!isDesktop && (
          <Icon
            iconName={isOpen ? 'DropdownArrowUpNew' : 'DropdownArrowDown'}
          />
        )}
      </Label>
      {isOpen && (
        <LanguageItems isDesktop={isDesktop}>
          {languages.map(({ lang, label, href }) => (
            <LanguageItem
              key={lang}
              href={href}
              isCurrentLanguage={lang === currentLang}
            >
              <Image src={getLanguageImageSrc(lang as Lang)} />
              <Text variant='content-03'>{label}</Text>
            </LanguageItem>
          ))}
        </LanguageItems>
      )}
    </Container>
  )
}

export default LanguageMenu
