import { StyledGrid } from 'shared/style/grid'
import styled from 'styled-components'

export const Container = styled(StyledGrid)<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;

  h2 {
    text-align: center;
    font-weight: 600;
    color: ${({ $color }) => $color};
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    h3 {
      font-size: 48px;
      line-height: 56px;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  @media (min-width: 768px) {
    max-width: 1192px;
  }
`

export const Card = styled.div`
  border: 1px solid #c9c9cb;
  border-radius: 6px;
  background: #ffffff;
`

export const CardContentContainer = styled.div`
  display: flex;
  align-items: stretch;
`

export const TextContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  background-color: ${({ $backgroundColor: $backgroundColor }) =>
    $backgroundColor};
  border-radius: 4px 0px 0px 4px;
  padding: 0.25rem;

  h3 {
    color: #fefefe;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    min-width: 82px;
  }
`

export const Content = styled.span`
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  font-weight: 400;
  padding: 1rem;
  text-align: justify;
  align-self: center;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`
