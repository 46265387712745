import styled, { css } from 'styled-components'

export type Placement = 'left' | 'right'

export const Container = styled.nav`
  position: relative;
  display: flex;
  min-height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
`

export const MenuGroupTrigger = styled.button`
  border: 0;
  background: none;
  padding: 0;
  appearance: none;

  @media (min-width: 768px) {
    display: none;
  }
`

export const MenuGroup = styled.ul`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: calc(100% + 2px);
  padding: 0.5rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100vw;
  z-index: 2;

  h2 {
    margin-bottom: 0.5rem;

    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    [aria-expanded='false'] + & {
      display: none;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    padding: 0;
    flex-direction: row;
    width: auto;
    flex-shrink: 0;
  }
`

export const MenuItems = styled.ul<{
  visibleTabletUp: boolean
  placement: Placement
}>`
  list-style: none;
  padding: 0;

  li {
    padding: 0.5rem;
  }

  a {
    color: inherit;
    display: block;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    margin: 0.5rem 0 0 0;
    background: ${({ theme }) => theme.colors.white};
    min-width: 325px;
    border-radius: 0 0 6px 6px;
    transition-property: visibility, opacity;
    transition-duration: 0.15s;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    border-top: 0;

    ${({ placement }) =>
      placement === 'left'
        ? css`
            left: 1rem;
          `
        : css`
            right: 1rem;
            text-align: right;
          `}

    ${({ visibleTabletUp }) =>
      visibleTabletUp &&
      css`
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      `}
  }
`

export const MenuTriggerWrapper = styled.li`
  position: relative;
`

export const MenuTrigger = styled.div<{ placement: Placement }>`
  display: flex;
  align-items: center;

  button {
    appearance: none;
    border: 0;
    background: none;

    ${({ placement }) =>
      placement === 'left'
        ? css`
            padding: 0 0 0 1rem;
          `
        : css`
            padding: 0 1rem 0 0;
            order: -1;
          `}
  }

  @media (max-width: 767px) {
    display: none;
  }
`
