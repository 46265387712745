import { RepresentationEnum } from 'site/shared/enums'
import { TopAreaPositionType } from 'site/shared/types'

import BreadcrumbsArea from './BreadcrumbsArea'
import DescriptionArea from './DescriptionArea'
import LinkArea from './LinkArea'
import Login from './Login/Login'
import LogoArea from './LogoArea'
import ManagementDashboard from './ManagementDashboard'
import NavigationMenuArea from './NavigationMenuArea'

const Position = (props: TopAreaPositionType): JSX.Element | null => {
  const { representation, routes, component } = props

  const handleRepresentation = () => {
    switch (representation) {
      case RepresentationEnum.LogoArea:
        return <LogoArea {...props} />

      case RepresentationEnum.Component:
        if (component === 'Login') {
          return <Login />
        }

        return null

      case RepresentationEnum.Des:
        return <DescriptionArea {...props} />

      case RepresentationEnum.Link:
        return <LinkArea {...props} />

      case RepresentationEnum.Breadcrumbs:
        if (routes) {
          return <BreadcrumbsArea {...props} />
        }

        return null

      case RepresentationEnum.NavigationMenu:
        return <NavigationMenuArea {...props} />

      case RepresentationEnum.ManagementDashboard:
        return <ManagementDashboard {...props} />

      default:
        return null
    }
  }

  return handleRepresentation()
}

export default Position
