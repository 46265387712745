import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useLogout = () => {
  const dispatch = useDispatch()

  const handleLoggedOut = useCallback(() => {
    dispatch({
      type: 'login/LOGOUT'
    })
  }, [dispatch])

  const handleLoggingOut = useCallback(() => {
    dispatch({
      type: 'login/LOGOUT_REQUEST'
    })
  }, [dispatch])

  return {
    handleLoggedOut,
    handleLoggingOut
  }
}

export default useLogout
